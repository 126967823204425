import { useEffect, useState } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

const MaintenanceComponent = () => {
  const [data, setData] = useState(null)
  const userTimezone = dayjs.tz.guess() // Lấy múi giờ local của user
  const userUtcOffset = dayjs().tz(userTimezone).utcOffset() / 60 // Lấy offset theo giờ

  useEffect(() => {
    const dataMaintenance = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}maintenances/maintenance-status`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        setData(response?.data?.data)
      } catch (error) {
        console.error('Error fetching maintenance data:', error)
      }
    }

    dataMaintenance()
  }, [])

  return (
    <div className="text-[30px] mt-[400px] flex flex-col text-center">
      <p>Currently under maintenance.</p>
      <p>The following work hours are scheduled</p>
      {data && (
        <div>
          {/* <p>
            <strong>UTC Time:</strong>{' '}
            {dayjs.utc(data.time_start).format('YYYY-MM-DD HH:mm:ss')} -{' '}
            {dayjs.utc(data.time_end).format('YYYY-MM-DD HH:mm:ss')} UTC
          </p> */}
          <p>
            {/* <strong>
              Local Time (UTC{' '}
              {userUtcOffset >= 0 ? `+${userUtcOffset}` : userUtcOffset}):
            </strong>{' '} */}
            {dayjs
              .utc(data.time_start)
              .tz(userTimezone)
              .format('YYYY-MM-DD HH:mm:ss')}{' '}
            (UTC {userUtcOffset >= 0 ? `+${userUtcOffset}` : userUtcOffset}) -{' '}
            {dayjs
              .utc(data.time_end)
              .tz(userTimezone)
              .format('YYYY-MM-DD HH:mm:ss')}
            (UTC {userUtcOffset >= 0 ? `+${userUtcOffset}` : userUtcOffset})
          </p>
        </div>
      )}
    </div>
  )
}

export default MaintenanceComponent
