import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Popover,
  Row,
  Spin,
  Upload,
  message,
  notification,
} from 'antd'
import {
  ALLOW_AVATAR_TYPES,
  NOTIFICATION_DURATION,
  REACT_APP_BASE_URL_GAME,
} from '~/common/constants'
import { useTranslation } from '~/common/hooks/useTranslation'
import BodyProfile from '~/components/shared-components/BodyProfile'
import { useAccountProfile } from '~/views/redux/hooks/useAccountProfile'
import ModalEmailVerify from './Modals/modalEmailVerify'
import ModalPhoneVerify from './Modals/modalPhoneVerify'
import BridgeOAS from '~/components/Modals/BridgeOAS'
import { getBalanceOAS } from '~/blockchain/provider'
import { formatNumber } from '~/helpers/common'
import DepositToOasModal from './Modals/depositToOasModal'
import { useMonster } from '~/views/redux/hooks/useMonster'
import { useFarm } from '~/views/redux/hooks/useFarm'
import { useItem } from '~/views/redux/hooks/useItem'
import WithdrawFromOasModal from './Modals/withdrawFromOasModal'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  TwitterAuthProvider,
  signInWithPopup,
  AuthErrorCodes,
} from 'firebase/auth'
import { auth } from '~/firebase'
import ModalVerifyFail from './Modals/modalVerifyFail'
import ModalVerifySuccess from './Modals/modalVerifySuccess'
import en from 'world_countries_lists/data/countries/en/world.json'
import { CountryPhoneInput } from '~/components/shared-components/InputPhone/phone-input'
import { ConfigProviderPhone } from '~/components/shared-components/InputPhone/config'
import ChangeLanguage from './Form/ChangeLanguage'
import { REGEX } from '~/helpers/regex'

export const ProfileAccountSettings = () => {
  const { t } = useTranslation()
  const mailInput = useRef(null)
  const [formName] = Form.useForm()
  const [formEmail] = Form.useForm()
  const [formPhone] = Form.useForm()
  const [formTwitter] = Form.useForm()
  const [isModalEmailOpen, setIsModalEmailOpen] = useState({
    isOpen: false,
    email: null,
  })
  const [isModalPhoneOpen, setIsModalPhoneOpen] = useState({
    isOpen: false,
    phone: null,
    code: null,
  })
  const [isModalVerifySuccessOpen, setIsModalVerifySuccessOpen] =
    useState(false)
  const [isModalVerifyFailedOpen, setIsModalVerifyFailedOpen] = useState(false)
  const [isModalDepositToOas, setIsModalDepositToOas] = useState(false)
  const [isModalWithdrawFromOas, setIsModalWithdrawFromOas] = useState(false)
  const [isLoadingImage, setIsLoadingImage] = useState(false)
  const [disableVerify, setDisableVerify] = useState(false)
  const {
    actions: actionTheme,
    data: { user },
  } = useTheme()

  const [isModalDepositOpen, setIsModalDepositOpen] = useState(false)
  const [balanceOAS, setBalanceOAS] = useState(0)
  const [resultPhone, setResultPhone] = useState('')
  const loadBalanceOAS = () => {
    getBalanceOAS(user.address_wallet).then((res) => {
      setBalanceOAS(res)
    })
  }

  const showModalVerifySuccess = () => {
    setIsModalVerifySuccessOpen(true)
  }

  const handleCancelModalVerifySuccess = () => {
    setIsModalVerifySuccessOpen(false)
    refreshDataAccount()
  }

  const showModalVerifyFailed = () => {
    setIsModalVerifyFailedOpen(true)
  }

  const handleCancelVerifyFail = () => {
    handleCancelModalVerifyFailed()
    setIsModalPhoneOpen({
      isOpen: false,
      phone: null,
      code: null,
    })
    setIsModalEmailOpen({
      isOpen: false,
      email: null,
    })
  }

  const handleCancelModalVerifyFailed = () => {
    refreshDataAccount()
    setIsModalVerifyFailedOpen(false)
  }

  const handleDeposit = () => {
    setIsModalDepositOpen(false)
  }
  // eslint-disable-next-line
  const showModalDeposit = () => {
    setIsModalDepositOpen(true)
  }

  const cancelModalDeposit = () => {
    setIsModalDepositOpen(false)
  }
  const [checkVerify, setCheckVerify] = useState(null)
  const checkNumberVerify = (number) => {
    setCheckVerify(number)
  }

  const {
    actions: actionsMonster,
    data: { totalMonsterUser },
  } = useMonster()
  const {
    actions: actionsItem,
    data: { totalItemUser },
  } = useItem()

  const {
    actions: actionsFarm,
    data: { totalFarmsUser },
  } = useFarm()

  const {
    actions,
    data: { playerInfo },
  } = useAccountProfile()
  const rules = {
    name: [
      () => ({
        validator(rule, value) {
          const regex = new RegExp(REGEX.PROFILE_NAME)
          if (value) {
            // Reject if value contains hyphens
            if (value.includes('-')) {
              return Promise.reject(
                t('profile.accountSetting.personalInformation.validateName'),
              )
            }
            // Validate against the regex pattern
            if (!regex.test(value)) {
              return Promise.reject(
                t('profile.accountSetting.personalInformation.validateName'),
              )
            }
          }
          return Promise.resolve()
        },
      }),

      {
        required: true,
        message: t('validate.required', {
          0: t('profile.accountSetting.personalInformation.playerName'),
        }),
      },
    ],
    email: [
      () => ({
        validator: (rule, value) => {
          const regex = new RegExp(REGEX.PROFILE_EMAIL)
          const atIndex = value?.indexOf('@')
          if (value && atIndex !== -1) {
            const domain = value?.slice(atIndex + 1)
            if (
              value &&
              (domain.split('.').find((part) => part === '') ||
                domain.startsWith('-'))
            ) {
              return Promise.reject(
                t('profile.accountSetting.personalInformation.validateEmail'),
              )
            }
          }
          if (value && !regex.test(value)) {
            return Promise.reject(
              t('profile.accountSetting.personalInformation.validateEmail'),
            )
          }

          return Promise.resolve()
        },
      }),
      // {
      //   type: 'email',
      //   message: t('profile.accountSetting.personalInformation.validateEmail'),
      // },
    ],
    twitter: [
      () => ({
        validator(rule, value) {
          const regex = new RegExp(REGEX.PROFILE_TWITTER)
          if (value) {
            // Reject if value contains hyphens
            if (value.includes('-')) {
              return Promise.reject(
                t('profile.accountSetting.personalInformation.validateTwitter'),
              )
            }
            // Validate against the regex pattern
            if (!regex.test(value)) {
              return Promise.reject(
                t('profile.accountSetting.personalInformation.validateTwitter'),
              )
            }
          }
          return Promise.resolve()
        },
      }),
    ],
  }

  const handleTwitterSignIn = async () => {
    try {
      const provider = new TwitterAuthProvider()
      signInWithPopup(auth, provider)
        .then((result) => {
          const params = {
            player_name: playerInfo?.user_name,
            player_email: playerInfo?.email,
            player_phone: playerInfo?.player_phone,
            player_language: playerInfo?.player_language,
            player_twitter: result?.user?.displayName,
          }
          actions.updateAccountProfile(params, (res) => {
            actionTheme.updateInfoUser(res?.player_info)
          })
        })
        .catch(async (error) => {
          // eslint-disable-next-line
          console.log(error)
        })
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
    }
  }

  const showModalEmailVerify = (email) => {
    setIsModalEmailOpen({
      isOpen: true,
      email: email,
    })
  }

  const showModaDepositToOas = () => {
    setIsModalDepositToOas(true)
  }
  const showModaWithdrawFromOas = () => {
    setIsModalWithdrawFromOas(true)
  }

  const handleCancel = () => {
    setIsModalEmailOpen({
      isOpen: false,
      email: null,
    })
    setIsModalPhoneOpen({
      isOpen: false,
      phone: null,
      code: null,
    })
    refreshDataAccount()
    setIsModalDepositToOas(false)
    setIsModalWithdrawFromOas(false)
  }

  const handleChangeAvatar = useCallback(async (fileAvatar) => {
    try {
      const isFileTypeValid = ALLOW_AVATAR_TYPES.includes(fileAvatar.file?.type)
      const LIMIT_SIZE_OF_IMAGE = 2 * 1000000 // 2 megabytes in bytes

      if (!isFileTypeValid) {
        return
      }
      if (fileAvatar?.file?.size > LIMIT_SIZE_OF_IMAGE) {
        return // Prevent upload
      }

      setIsLoadingImage(true)
      if (fileAvatar.file.status === 'done') {
        message.success(
          t('profile.accountSetting.uploadFile.success', {
            nameFile: fileAvatar.file.name,
          }),
        )
        actions.getAccountProfile((res) => {
          actionTheme.updateInfoUser({
            ...res?.player_info,
          })
          setIsLoadingImage(false)
        })
      } else if (fileAvatar.file.status === 'error') {
        message.error(
          t('profile.accountSetting.uploadFile.fail', {
            nameFile: fileAvatar.file.name,
          }),
        )
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error('error' + error.message)
    }
  }, [])

  const handleSubmit = async (values) => {
    const params = {
      player_name: values.name,
      player_email: playerInfo.email,
      player_phone: playerInfo?.player_phone,
      player_language: user?.player_language,
      player_twitter: playerInfo?.player_twitter,
      player_bio: playerInfo?.player_bio,
    }
    actions.updateAccountProfile(params, (res) => {
      formName.setFieldsValue({ name: values.name })
      actionTheme.updateInfoUser(res?.player_info)
      refreshDataAccount()
      message.success(
        t('profile.accountSetting.personalInformation.updateName'),
      )

      if (values.name) {
        setCheckReadonlyName(true)
      }
    })
  }

  //Refresh data
  const refreshDataAccount = () => {
    actions.getPlayerInfo()
  }

  const refreshDataMonter = useCallback(() => {
    const params = {
      page: 1,
      pageSize: 10,
      wallet_address: user?.address_wallet,
    }
    actionsMonster.getAllMonsterUser(params)
  }, [])

  const refreshDataFarm = useCallback(() => {
    const params = {
      page: 1,
      pageSize: 10,
      wallet_address: user?.address_wallet,
    }
    actionsFarm.getAllFarmUser(params)
  }, [])

  const refreshDataItem = useCallback(() => {
    const params = {
      page: 1,
      pageSize: 10,
      wallet_address: user?.address_wallet,
    }
    actionsItem.getAllItemUser(params)
  }, [])

  const setUpRecaptcha = () => {
    try {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        'recaptcha-container',
        {
          size: 'invisible',
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // ...
          },
          'expired-callback': () => {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
          },
        },
      )
    } catch (err) {
      // eslint-disable-next-line
      console.log(err)
    }
  }

  useEffect(() => {
    setUpRecaptcha()
  }, [])
  const onGetOtp = async (phone, code, pathPhone) => {
    setDisableVerify(true)
    startCountdown()
    try {
      await signInWithPhoneNumber(auth, phone, window.recaptchaVerifier)
        ?.then((res) => {
          setResultPhone(res)
          setIsModalPhoneOpen({
            isOpen: true,
            phone: pathPhone,
            code: code,
          })
        })
        ?.catch((err) => {
          // eslint-disable-next-line
          console.log(err)
          var id = AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER
          var id2 = AuthErrorCodes.QUOTA_EXCEEDED
          const errorCode = err.message.replace(/^.*\(([^)]+)\).*$/, '$1')
          if (errorCode === id || errorCode === id2) {
            notification.error({
              message: t(
                'profile.accountSetting.personalInformation.messageError1',
              ),
              duration: NOTIFICATION_DURATION,
            })
          } else {
            notification.error({
              message: t(
                'profile.accountSetting.personalInformation.messageError2',
              ),
              duration: NOTIFICATION_DURATION,
            })
          }
        })
    } catch (err) {
      notification.error({
        message: err.message,
        duration: NOTIFICATION_DURATION,
      })
      window.recaptchaVerifier = null
    }
  }

  const handleSubmitPhone = (values) => {
    const params = {
      player_email: playerInfo?.email,
      player_phone: values.player_phone,
      player_name: playerInfo?.user_name,
      player_language: user?.player_language,
      player_twitter: playerInfo?.player_twitter,
      player_bio: playerInfo?.player_bio,
    }
    actions.updateAccountProfile(params, (res) => {
      if (values?.country_code === undefined) {
        formPhone.setFields([
          {
            name: 'country_code',
            errors: [
              t('validate.required', {
                0: t('profile.accountSetting.personalInformation.countryCode'),
              }),
            ],
          },
        ])
        return
      }
      if (values.player_phone) {
        const phone =
          '+' + values?.country_code + values?.player_phone?.replace('+', '')
        onGetOtp(
          phone,
          values?.country_code,
          values?.player_phone?.replace('+', ''),
        )
      }
      if (!values.player_phone) {
        refreshDataAccount()
        message.success(
          t('profile.accountSetting.personalInformation.updatePhone'),
        )
      }
    })
  }

  const handleSubmitEmail = (values) => {
    const params = {
      player_email: values.email,
      player_phone: playerInfo?.player_phone,
      player_name: playerInfo?.user_name,
      player_language: user?.player_language,
      player_twitter: playerInfo?.player_twitter,
      player_bio: playerInfo?.player_bio,
    }
    actions.updateAccountProfile(params, (res) => {
      if (values.email) {
        actions?.sendVerifyEmail(null, (res) => {
          if (res?.success === false) {
            message.error(res?.message)
          } else {
            showModalEmailVerify(values.email)
          }
        })
      }
      if (!values.email) {
        message.success(
          t('profile.accountSetting.personalInformation.updateEmail'),
        )
      }
    })
  }

  const handleSubmitTwitter = (values) => {
    const params = {
      player_email: playerInfo?.email,
      player_phone: playerInfo?.player_phone,
      player_name: playerInfo?.user_name,
      player_language: user?.player_language,
      player_twitter: values.twitter,
      player_bio: playerInfo?.player_bio,
    }
    actions.updateAccountProfile(params, (res) => {
      formTwitter.setFieldsValue({ twitter: values?.twitter })
      actionTheme.updateInfoUser(res?.player_info)
      refreshDataAccount()
      message.success(
        t('profile.accountSetting.personalInformation.updateTwitter'),
      )
      if (values.twitter) {
        setCheckReadonlyTwitter(true)
      }
    })
  }

  useEffect(() => {
    if (user) {
      loadBalanceOAS()
    }
  }, [])

  useEffect(() => {
    if (playerInfo) {
      formName.setFieldsValue({ name: playerInfo?.user_name })
      formTwitter.setFieldsValue({ twitter: playerInfo?.player_twitter })
      formEmail.setFieldsValue({ email: playerInfo?.email })
      formPhone.setFieldsValue({ player_phone: playerInfo?.player_phone })
      formPhone.setFieldsValue({ country_code: playerInfo?.country_code })
    }
  }, [playerInfo])

  useEffect(() => {
    refreshDataAccount()
  }, [])

  useEffect(() => {
    if (user) {
      refreshDataMonter()
      refreshDataFarm()
      refreshDataItem()
    }
  }, [])

  // const getFieldValueEmail = (fieldName) => {
  //   return Form.useWatch(fieldName, formEmail)
  // }

  const handleBeforUploadImage = (file) => {
    const isFileTypeValid = ALLOW_AVATAR_TYPES.includes(file.type)
    const LIMIT_SIZE_OF_IMAGE = 2 * 1000000 // 2 megabytes in bytes

    if (!isFileTypeValid) {
      // message.error('Please upload an image file of type: jpg, png, heic.')
      message.error(t('profile.accountSetting.uploadFile.errorTypeValidate'))
    }
    if (file.size > LIMIT_SIZE_OF_IMAGE) {
      // message.error('Please upload file below 2MB.')
      message.error(t('profile.accountSetting.uploadFile.errorSize'))
      return false // Prevent upload
    }
    return isFileTypeValid
  }

  //setTimout disableVerify
  const [countdown, setCountdown] = useState(30)
  const [intervalId, setIntervalId] = useState(null)
  const startCountdown = () => {
    const id = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 0) {
          clearInterval(id)
          setDisableVerify(false)
          return 30
        }
        return prevCountdown - 1
      })
    }, 1000)
    setIntervalId(id)
  }

  useEffect(() => {
    return () => {
      clearInterval(intervalId)
    }
  }, [intervalId])
  const [emailValue, setEmailValue] = useState(null)
  const [phoneValue, setPhoneValue] = useState(null)
  const [checkReadonlyEmail, setCheckReadonlyEmail] = useState(false)
  const [checkReadonlyTwitter, setCheckReadonlyTwitter] = useState(false)
  const [checkReadonlyName, setCheckReadonlyName] = useState(false)
  const [checkReadonlyPhone, setCheckReadonlyPhone] = useState(false)
  const [checkDisableEmail, setCheckDisableEmail] = useState(null)
  const [checkDisablePhone, setCheckDisablePhone] = useState(null)
  const [checkDisableName, setCheckDisableName] = useState(null)
  const [checkDisableTwitter, setCheckDisableTwitter] = useState(null)
  const handleChangeEmail = (e) => {
    setEmailValue(e.target.value)
    if (e.target.value === playerInfo?.email) {
      setCheckDisableEmail(true)
    } else {
      setCheckDisableEmail(false)
    }
  }

  const handleChangeName = (e) => {
    if (e.target.value === playerInfo?.user_name) {
      setCheckDisableName(true)
    } else {
      setCheckDisableName(false)
    }
  }
  const handleChangeTwitter = (e) => {
    if (e.target.value === playerInfo?.player_twitter) {
      setCheckDisableTwitter(true)
    } else {
      setCheckDisableTwitter(false)
    }
  }
  useEffect(() => {
    setEmailValue(playerInfo?.email)
    setPhoneValue(playerInfo?.player_phone)
  }, [playerInfo])
  useEffect(() => {
    if (playerInfo?.email_verified) {
      setCheckReadonlyEmail(true)
    }
  }, [playerInfo?.email_verified])
  useEffect(() => {
    if (playerInfo?.phone_verified) {
      setCheckReadonlyPhone(true)
    }
    if (phoneValue === undefined) {
      setCheckDisablePhone(true)
    } else {
      setCheckDisablePhone(false)
    }
  }, [playerInfo?.phone_verified])
  useEffect(() => {
    if (phoneValue === undefined) {
      setCheckDisablePhone(true)
    } else {
      setCheckDisablePhone(false)
    }
    if (
      phoneValue === playerInfo?.player_phone &&
      playerInfo?.phone_verified === true
    ) {
      setCheckDisablePhone(true)
    } else {
      setCheckDisablePhone(false)
    }
  }, [playerInfo?.phone_verified, playerInfo?.player_phone, phoneValue])
  useEffect(() => {
    if (playerInfo?.player_twitter) {
      setCheckReadonlyTwitter(true)
    }
  }, [playerInfo?.player_twitter])
  useEffect(() => {
    if (playerInfo?.user_name) {
      setCheckReadonlyName(true)
    }
  }, [playerInfo?.user_name])
  const onChangePhone = (e) => {
    setPhoneValue(e?.target?.value)
    if (e?.target?.value === playerInfo?.player_phone) {
      setCheckDisablePhone(true)
    } else {
      setCheckDisablePhone(false)
    }
    return formPhone.setFields([
      {
        name: 'country_code',
        errors: [],
      },
    ])
  }
  return (
    <BodyProfile>
      <div id="recaptcha-container"></div>
      {/* <SharedOnTwitter url="http://remonster.blob.core.windows.net/public/image/gif/Ame_chan_Ame_chan_1.gif" /> */}
      <div>
        <div className="text-[24px] mb-4">
          {t('profile.menu.accountSettings')}
        </div>
        <Row className="items-start mb-4">
          <Col xl={16} md={16} lg={24} sm={24} xs={24} className="mb-4">
            <div className="text-[24px] mb-4">
              {t('profile.accountSetting.personalInformation.title')}
            </div>
            <Card>
              {/* playerName + email */}
              <Row gutter={10}>
                <Col xl={12} md={12} sm={24} xs={24}>
                  <Form
                    layout="vertical"
                    onFinish={handleSubmit}
                    form={formName}
                    translate="yes"
                    autoComplete="off"
                    size="middle"
                    validateTrigger={['onBlur', 'onChange']}
                    requiredMark={false}
                  >
                    <Row className="mb-3 md:mb-0" gutter={10}>
                      <Col xl={16} md={16} sm={24} xs={24}>
                        <Form.Item
                          label={
                            <span className="font__M__plus font-bold">
                              {t(
                                'profile.accountSetting.personalInformation.playerName',
                              )}
                              <span style={{ color: 'red' }}> *</span>
                            </span>
                          }
                          name="name"
                          rules={rules.name}
                          labelAlign="left"
                        >
                          <Input
                            className={`h-[44px] font-bold ${
                              checkReadonlyName ? 'bg-[#283E79]' : ''
                            }`}
                            style={{
                              border: checkReadonlyName
                                ? '1px solid #283e79'
                                : '',
                            }}
                            readOnly={checkReadonlyName}
                            placeholder={t(
                              'profile.accountSetting.personalInformation.playerName',
                            )}
                            onChange={handleChangeName}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xl={8}
                        md={8}
                        sm={24}
                        xs={24}
                        className="block items-center justify-center w-full"
                        style={{ minWidth: '100px' }}
                      >
                        <div className="h-[27px]"></div>
                        {/* <Button
                          className="btnVerify bg-[#1890FF] w-full h-[42px] mt-2"
                          type="primary"
                          htmlType="submit"
                          block
                          disabled={checkDisableName}
                        >
                          {t('common.btn.change')}
                        </Button> */}
                        {!checkReadonlyName && (
                          <Button
                            className=" btnVerify bg-[#1890FF] w-full h-[42px] mt-2"
                            type="primary"
                            htmlType="submit"
                            disabled={checkDisableName}
                          >
                            <div>{t('common.btn.save')}</div>
                          </Button>
                        )}
                        {checkReadonlyName && (
                          <Button
                            className=" btnVerify bg-[#1890FF] w-full h-[42px] mt-2"
                            type="primary"
                            onClick={(e) => setCheckReadonlyName(false)}
                          >
                            <div>{t('common.btn.change')}</div>
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <Col xl={12} md={12} sm={24} xs={24}>
                  <Form
                    id="FormSendMail"
                    layout="vertical"
                    onFinish={handleSubmitEmail}
                    form={formEmail}
                    translate="yes"
                    autoComplete="off"
                    size="middle"
                    validateTrigger={['onBlur', 'onChange']}
                    requiredMark={false}
                  >
                    <Row className="mb-3 md:mb-0" gutter={10}>
                      <Col xl={16} md={16} sm={24} xs={24}>
                        <Form.Item
                          label={
                            <span className="font__M__plus font-bold ">
                              {t(
                                'profile.accountSetting.personalInformation.email',
                              )}
                            </span>
                          }
                          name="email"
                          rules={rules.email}
                          labelAlign="left"
                        >
                          <div
                            className={`${
                              checkReadonlyEmail ? 'ReadOnly' : ''
                            }`}
                          >
                            <Input
                              ref={mailInput}
                              value={emailValue}
                              className={`h-[44px] ${
                                checkReadonlyEmail ? 'bg-[#283E79]' : ''
                              } font-bold `}
                              placeholder={t(
                                'profile.accountSetting.personalInformation.email',
                              )}
                              onChange={handleChangeEmail}
                              readOnly={checkReadonlyEmail}
                              suffix={
                                <>
                                  {playerInfo?.email_verified && (
                                    <img
                                      src={`${process.env.PUBLIC_URL}/imgs/icons/verify_success.png`}
                                      alt=""
                                    />
                                  )}
                                </>
                              }
                            />
                          </div>
                        </Form.Item>
                      </Col>
                      <Col
                        xl={8}
                        md={8}
                        sm={24}
                        xs={24}
                        className="flex items-center justify-center w-full"
                        style={{ minWidth: '100px' }}
                      >
                        {(!playerInfo?.email_verified ||
                          !checkReadonlyEmail) && (
                          <Button
                            className=" btnVerify bg-[#1890FF] w-full h-[42px] mt-2"
                            type="primary"
                            htmlType="submit"
                            disabled={checkDisableEmail}
                          >
                            <span className="ml-[-6px]">
                              {emailValue !== playerInfo?.email
                                ? emailValue
                                  ? t('common.btn.saveAndVerify')
                                  : t('common.btn.save')
                                : t('common.btn.verify')}
                            </span>
                          </Button>
                        )}
                        {playerInfo?.email_verified && checkReadonlyEmail && (
                          <Button
                            className="bg-[#1890FF] w-full h-[42px] mt-2"
                            type="primary"
                            onClick={(e) => setCheckReadonlyEmail(false)}
                          >
                            <span className="ml-[-6px]">
                              {t('common.btn.change')}
                            </span>
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              {/* phone + x connect */}
              <Row gutter={10}>
                <Col xl={12} md={12} sm={24} xs={24}>
                  <Form
                    layout="vertical"
                    onFinish={disableVerify ? null : handleSubmitPhone}
                    form={formPhone}
                    translate="yes"
                    autoComplete="off"
                    size="middle"
                    validateTrigger={['onBlur', 'onChange']}
                    initialValues={{ country_code: '84' }}
                    requiredMark={false}
                  >
                    <Row className="mb-3 md:mb-0" gutter={10}>
                      <Col xl={16} md={16} sm={24} xs={24}>
                        <ConfigProviderPhone locale={en}>
                          <CountryPhoneInput
                            playerInfo={playerInfo}
                            onChangePhone={onChangePhone}
                            className="h-[44px] font-bold"
                            checkReadonlyPhone={checkReadonlyPhone}
                            phoneValue={phoneValue}
                          />
                        </ConfigProviderPhone>
                      </Col>
                      <Col
                        xl={8}
                        md={8}
                        sm={24}
                        xs={24}
                        className="flex items-center justify-center w-full"
                        style={{ minWidth: '100px' }}
                      >
                        {(!playerInfo?.phone_verified || !checkReadonlyPhone) &&
                          (checkDisablePhone ? (
                            <Popover
                              content={<>{t('common.btn.messageNotVerify')}</>}
                              trigger="hover" // Popover appears on hover
                            >
                              <Button
                                htmlType="submit"
                                className={`btnVerify bg-[#1890FF] w-full h-[42px] mt-2 resize-text ${
                                  disableVerify ? 'cursor-not-allowed' : ''
                                }`}
                                type="primary"
                                disabled={checkDisablePhone}
                              >
                                <span className="ml-[-6px]">
                                  {disableVerify ? (
                                    <div>{countdown}</div>
                                  ) : (
                                    <>
                                      {phoneValue !== playerInfo?.player_phone
                                        ? phoneValue
                                          ? t('common.btn.saveAndVerify')
                                          : t('common.btn.save')
                                        : t('common.btn.verify')}
                                    </>
                                  )}
                                </span>
                              </Button>
                            </Popover>
                          ) : (
                            <Button
                              htmlType="submit"
                              className={`btnVerify bg-[#1890FF] w-full h-[42px] mt-2 resize-text ${
                                disableVerify ? 'cursor-not-allowed' : ''
                              }`}
                              type="primary"
                              disabled={checkDisablePhone}
                            >
                              <span className="ml-[-6px]">
                                {disableVerify ? (
                                  <div>{countdown}</div>
                                ) : (
                                  <>
                                    {phoneValue !== playerInfo?.player_phone
                                      ? phoneValue
                                        ? t('common.btn.saveAndVerify')
                                        : t('common.btn.save')
                                      : t('common.btn.verify')}
                                  </>
                                )}
                              </span>
                            </Button>
                          ))}

                        {/* {playerInfo?.phone_verified && checkReadonlyPhone && (
                          <Button
                            className="bg-[#1890FF] w-full h-[42px] mt-2"
                            type="primary"
                            onClick={(e) => setCheckReadonlyPhone(false)}
                          >
                            <span className="ml-[-6px]">
                              {t('common.btn.change')}
                            </span>
                          </Button>
                        )} */}
                        {playerInfo?.phone_verified &&
                          checkReadonlyPhone &&
                          (disableVerify === false ? (
                            <Button
                              className="bg-[#1890FF] w-full h-[42px] mt-2"
                              type="primary"
                              onClick={(e) => setCheckReadonlyPhone(false)}
                            >
                              <span className="ml-[-6px]">
                                {t('common.btn.change')}
                              </span>
                            </Button>
                          ) : (
                            <Button
                              className="bg-[#1890FF] w-full h-[42px] mt-2 cursor-not-allowed"
                              type="primary"
                            >
                              <span className="ml-[-6px]">{countdown}</span>
                            </Button>
                          ))}
                      </Col>
                    </Row>
                  </Form>
                </Col>
                <Col xl={12} md={12} sm={24} xs={24}>
                  {/* Language */}
                  <ChangeLanguage t={t} playerInfo={playerInfo} />
                </Col>
              </Row>
              {/* twitter */}
              {/* <Row className="flex justify-end" gutter={10}>
                <Col
                  xl={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className="flex items-center justify-center w-full"
                  style={{
                    justifyContent: 'flex-end',
                    minWidth: '100px',
                  }}
                >
                  {playerInfo?.player_twitter}
                </Col>
                <Col
                  xl={4}
                  md={4}
                  sm={24}
                  xs={24}
                  className="flex items-center justify-center w-full"
                  style={{
                    justifyContent: 'flex-end',
                    minWidth: '100px',
                  }}
                >
                  <Button
                    className="flex items-center bg-[#1890FF] w-full h-[42px] mt-2"
                    type="primary"
                    onClick={handleTwitterSignIn}
                  >
                    <img
                      className="h-[24px] mr-3"
                      src="../svg/twitter.svg"
                      alt=""
                    />
                    <div>{t('common.btn.connect')}</div>
                  </Button>
                </Col>
              </Row> */}
              <Row className="flex justify-end" gutter={10}>
                <Col xl={12} md={12} sm={24} xs={24}>
                  <Form
                    id="FormSendMail"
                    layout="vertical"
                    onFinish={handleSubmitTwitter}
                    form={formTwitter}
                    translate="yes"
                    autoComplete="off"
                    size="middle"
                    validateTrigger={['onBlur', 'onChange']}
                    requiredMark={false}
                  >
                    <Row className="mb-3 md:mb-0" gutter={10}>
                      <Col xl={16} md={16} sm={24} xs={24}>
                        <Form.Item
                          label={
                            <span className="font__M__plus font-bold flex items-center  ">
                              <img
                                className="h-[12px] mr-1"
                                src="../svg/twitter.svg"
                                alt=""
                              />
                              {t(
                                'profile.accountSetting.personalInformation.twitter',
                              )}
                            </span>
                          }
                          name="twitter"
                          rules={rules.twitter}
                          labelAlign="left"
                        >
                          <Input
                            className={`h-[44px] font-bold ${
                              checkReadonlyTwitter ? 'bg-[#283E79]' : ''
                            }`}
                            style={{
                              border: checkReadonlyTwitter
                                ? '1px solid #283e79'
                                : '',
                            }}
                            readOnly={checkReadonlyTwitter}
                            onChange={handleChangeTwitter}
                            placeholder={t(
                              'profile.accountSetting.personalInformation.twitter',
                            )}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xl={8}
                        md={8}
                        sm={24}
                        xs={24}
                        className="block items-center justify-center w-full"
                        style={{ minWidth: '100px' }}
                      >
                        <div className="h-[27px]"></div>
                        {!checkReadonlyTwitter && (
                          <Button
                            className=" btnVerify bg-[#1890FF] w-full h-[42px] mt-2"
                            type="primary"
                            htmlType="submit"
                            disabled={checkDisableTwitter}
                          >
                            <div className="flex items-center">
                              <img
                                className="h-[24px] mr-3"
                                src="../svg/twitter.svg"
                                alt=""
                              />
                              <div>{t('common.btn.save')}</div>
                            </div>
                          </Button>
                        )}
                        {checkReadonlyTwitter && (
                          <Button
                            className=" btnVerify bg-[#1890FF] w-full h-[42px] mt-2"
                            type="primary"
                            onClick={(e) => setCheckReadonlyTwitter(false)}
                          >
                            <div className="flex items-center">
                              <img
                                className="h-[24px] mr-3"
                                src="../svg/twitter.svg"
                                alt=""
                              />
                              <div>{t('common.btn.change')}</div>
                            </div>
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
          {/* Image */}
          <Col
            xl={8}
            md={8}
            lg={24}
            sm={24}
            xs={24}
            className="flex flex-col items-center justify-center"
          >
            <div className="text-[24px] mb-4 text-center">
              {t('profile.accountSetting.personalInformation.profileImage')}
            </div>
            <div className="h-full flex items-center justify-center">
              <div>
                <Spin spinning={isLoadingImage}>
                  <Image
                    className="rounded-full"
                    width={164}
                    height={164}
                    src={`${user?.avatar}?date=${new Date()}`}
                    fallback={`${process.env.PUBLIC_URL}/imgs/common/avatar-default.png`}
                    loading="lazy"
                  />
                </Spin>
                <div
                  style={{
                    position: 'absolute',
                    borderRadius: '50%',
                    backgroundColor: '#FFF',
                    width: '42px',
                    height: '42px',
                    margin: ' -47px 0 0 125px',
                  }}
                >
                  <Upload
                    accept={ALLOW_AVATAR_TYPES}
                    action={`${REACT_APP_BASE_URL_GAME}/api/user/updateavatar`}
                    headers={{
                      authorization: `Bearer ${localStorage.getItem(
                        'auth_token',
                      )}`,
                    }}
                    beforeUpload={handleBeforUploadImage}
                    listType="picture"
                    onChange={(fileAvatar) => handleChangeAvatar(fileAvatar)}
                    name="file"
                    showUploadList={false}
                  >
                    <img
                      style={{ margin: '40%' }}
                      className="w-[24px] h-[24px]"
                      src="../svg/camera.svg"
                      alt=""
                    />
                  </Upload>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* wallet */}
        <div className="text-[24px] mb-4">
          {t('profile.accountSetting.wallet.title')}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
          <div className="flex flex-col-reverse md:flex-col gap-4">
            <Card className="w-full">
              <div className="flex items-center">
                <img
                  className="w-[60px] h-[60px] object-contain	mr-2"
                  src="../svg/coins/bit.svg"
                  alt=""
                />
                <div className="px-4">
                  <div className="text-[12px] md:text-[16px]">
                    {t('common.filter.effect.bit')}
                  </div>
                  <div className="text-[24px] md:text-[32px]">
                    {formatNumber(playerInfo?.bit_balance)}
                  </div>
                </div>
              </div>
            </Card>
            <div className="grid grid-cols-3 gap-4">
              <Card className="w-full">
                <div className="flex items-center flex-col lg:flex-row">
                  <img
                    className="w-[60px] h-[60px] object-contain	mr-2"
                    src="../svg/currency/Monter.svg"
                    alt=""
                  />
                  <div className="px-4 flex flex-col items-center lg:items-start">
                    <div className="text-[12px] md:text-[16px]">
                      {t('profile.menu.monsters')}
                    </div>
                    <div className="text-[24px] md:text-[32px]">
                      {totalMonsterUser}
                    </div>
                  </div>
                </div>
              </Card>

              <Card className="w-full">
                <div className="flex items-center flex-col lg:flex-row">
                  <img
                    className="w-[60px] h-[60px] object-contain	mr-2"
                    src="../svg/currency/farm1.svg"
                    alt=""
                  />
                  <div className="px-4 flex flex-col items-center lg:items-start">
                    <div className="text-[12px] md:text-[16px]">
                      {t('profile.menu.farms')}
                    </div>
                    <div className="text-[24px] md:text-[32px]">
                      {totalFarmsUser}
                    </div>
                  </div>
                </div>
              </Card>

              <Card className="w-full">
                <div className="flex items-center flex-col lg:flex-row">
                  <img
                    className="w-[60px] h-[60px] object-contain	mr-2"
                    src="../svg/currency/Banana.svg"
                    alt=""
                  />
                  <div className="px-4 flex flex-col items-center lg:items-start">
                    <div className="text-[12px] md:text-[16px]">
                      {t('profile.menu.items')}
                    </div>
                    <div className="text-[24px] md:text-[32px]">
                      {totalItemUser}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>

          <Card className="">
            <div className="mb-5 mt-4">
              <Row gutter={20}>
                <Col xl={12} md={12} lg={24} sm={24} xs={24}>
                  <div className="flex items-center mb-5">
                    <img
                      className=" w-[30px] h-[30px] md:w-[60px] md:h-[60px] object-contain	mr-2"
                      src="../svg/currency/OAS.svg"
                      alt=""
                    />
                    <div className="px-4">
                      <div className="text-[12px] md:text-[16px]">
                        {t('profile.menu.oasysMannet')}
                      </div>
                      <div className="text-[24px] md:text-[32px]">
                        {formatNumber(balanceOAS)} OAS
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={12} md={12} lg={24} sm={24} xs={24}>
                  <div className="flex items-center mb-5">
                    <img
                      className=" w-[30px] h-[30px] md:w-[60px] md:h-[60px] object-contain	mr-2"
                      src="../svg/currency/OAS.svg"
                      alt=""
                    />
                    <div className="px-4">
                      <div className="text-[12px] md:text-[16px]">
                        {t('profile.menu.mchVerse')}
                      </div>
                      <div className="text-[24px] md:text-[32px]">
                        {formatNumber(balanceOAS)} OAS
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="gap-4 mb-5 mt-4 ">
              <Row gutter={20}>
                <Col xl={12} md={12} lg={24} sm={24} xs={24}>
                  <Button
                    className="bg-[#1890FF] w-full h-[42px] mt-2 text-[12px] md:text-[16px]"
                    type="primary"
                    onClick={showModaDepositToOas}
                  >
                    {t('common.btn.depositToOas')}
                  </Button>
                </Col>
                <Col xl={12} md={12} lg={24} sm={24} xs={24}>
                  <Button
                    className="bg-[#1890FF] w-full h-[42px] mt-2 text-[12px] md:text-[16px]"
                    type="primary"
                    onClick={showModaWithdrawFromOas}
                  >
                    {t('common.btn.withdrawFromOas')}
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </div>

      <ModalEmailVerify
        action={{
          isModalOpen: isModalEmailOpen,
          isModalVerifyFailedOpen: isModalVerifyFailedOpen,
          handleCancel: handleCancel,
          showModalVerifySuccess: showModalVerifySuccess,
          showModalVerifyFailed: showModalVerifyFailed,
        }}
        t={t}
        checkNumberVerify={checkNumberVerify}
      />

      <ModalPhoneVerify
        action={{
          isModalVerifyFailedOpen: isModalVerifyFailedOpen,
          isModalOpen: isModalPhoneOpen,
          handleCancel: handleCancel,
          showModalVerifySuccess: showModalVerifySuccess,
          showModalVerifyFailed: showModalVerifyFailed,
        }}
        checkNumberVerify={checkNumberVerify}
        playerInfo={playerInfo}
        resultPhone={resultPhone}
        t={t}
      />

      <ModalVerifySuccess
        action={{
          isModalOpen: isModalVerifySuccessOpen,
          handleCancel: handleCancelModalVerifySuccess,
        }}
        t={t}
      />
      <ModalVerifyFail
        action={{
          isModalOpen: isModalVerifyFailedOpen,
          handleCancel: handleCancelModalVerifyFailed,
          handleCancelVerifyFail: handleCancelVerifyFail,
        }}
        t={t}
        checkVerify={checkVerify}
      />

      <BridgeOAS
        action={{
          isModalOpen: isModalDepositOpen,
          handleOk: handleDeposit,
          handleCancel: cancelModalDeposit,
        }}
        t={t}
        title={t('modal.deposit.title')}
        description={t('modal.deposit.description')}
        textConfirm={t('modal.deposit.ok')}
        textCancel={t('modal.deposit.cancel')}
        // loadingModal={loadingModal}
        balance={balanceOAS}
      />

      <DepositToOasModal
        action={{
          isModalOpen: isModalDepositToOas,
          handleCancel: handleCancel,
        }}
        t={t}
      />
      <WithdrawFromOasModal
        action={{
          isModalOpen: isModalWithdrawFromOas,
          handleCancel: handleCancel,
        }}
        t={t}
      />
    </BodyProfile>
  )
}

export default ProfileAccountSettings
