import { Button, Card, Col, Image, Popover, Row } from 'antd'
import { useLocation, useHistory } from 'react-router-dom'
import { ethers } from 'ethers'
import { useTranslation } from '~/common/hooks/useTranslation'
import BodyCommon from '~/components/shared-components/BodyCommon'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import TimeHelper from '~/helpers/time-helper'
import { ParameterItem } from './components/ParameterItem'
import { useItem } from '~/views/redux/hooks/useItem'
import { useEffect, useState } from 'react'
import {
  convertAddressToProperFormat,
  convertOasToUSD,
  formatAddress,
  formatItemName,
  formatNumber,
  formatTransaction,
} from '~/helpers/common'
import LoadingItemDetail from './components/LoadingItemDetail'
import {
  getBalanceItem,
  getNameItemV2,
  getNameItemV3,
} from '~/helpers/ItemName'
import configs from '~/configs'
import { useCommon } from '~/views/redux/hooks/useCommon'
import {
  checkTransactionConfirmation,
  fromWei,
  getBalanceOAS,
  hexToNumberString,
  numberToHex,
  toWei,
} from '~/blockchain/provider'
import { useAccount } from '~/common/hooks/useAccount'
import { useSDK } from '@metamask/sdk-react'
import CheckoutModal from '~/components/Modals/Checkout'
import CancelSell from '~/components/Modals/CancelSell'
import TransactionCompletedCancelSell from '~/components/Modals/TransactionCompletedCancelSell'
import TransactionCompletedMarketplace from '~/components/Modals/TransactionCompletedMarketplace'
import SelectWallet from '~/components/Modals/SelectWallet'
import { ethersWeb3Provider, providerOAS } from '~/blockchain/oas'
import {
  getTypeItem,
  ifaceContractMarketplace,
  getOrderIdFromTxHash,
} from '~/blockchain/contract'
import { changeNetworkInMetamask } from '~/blockchain/metamask'
import SellToMKPModal from '~/components/Modals/SellToMKPModal'
import TransactionCompletedSell from '~/components/Modals/TransactionCompletedSell'
import { ROUTE } from '~/views/routes/config'
import { getbgQuality } from '~/helpers/quality'
import SellToShopModal from '~/components/Modals/SellToShopModal'
import { useShop } from '~/views/redux/hooks/useShop'
import { useAccountProfile } from '~/views/redux/hooks/useAccountProfile'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import TransactionCompletedSellShop from '~/components/Modals/TransactionCompletedSellShop'
import getImageHash from '~/assets/data/ImageHash.json'
import ModalTransferNFT from '~/components/Modals/ModalTransferNFT'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { getMaxParameter, getRankbyValue } from '~/common/constants'
import ModalTransferNFTSuccess from '~/components/Modals/ModalTransferNFTSuccess'

export const ItemsDetail = () => {
  const { sdk } = useSDK()
  // eslint-disable-next-line
  const [txHash, setTxHash] = useState()
  const { user } = useAccount()
  dayjs.extend(utc)
  dayjs.extend(timezone)
  const userTimezone = dayjs.tz.guess()
  const userUtcOffset = dayjs().tz(userTimezone).utcOffset() / 60
  const [balanceOAS, setBalanceOAS] = useState(0)
  const [loadingModal, setLoadingModal] = useState(false)
  const [isModalSuccessOpenBuy, setIsModalSuccessOpenBuy] = useState(false)
  const [isModalSuccessOpenCancelSell, setIsModalSuccessOpenCancelSell] =
    useState(false)
  const [isModalConnectOpen, setIsModalConnectOpen] = useState(false)
  const [isModalOpenBuy, setIsModalOpenBuy] = useState(false)
  const [isModalOpenCancel, setIsModalOpenCancel] = useState(false)
  const [isModalSuccessSellOpen, setIsModalSuccessSellOpen] = useState(false)
  const [isModalSellOpen, setIsModalSellOpen] = useState(false)
  const [isModalOpenShop, setIsModalOpenShop] = useState(false)
  const [isModalSuccessShopOpen, setIsModalSuccessOpenShop] = useState(false)
  const [isModalTransferNFT, setIsModalTransferNFT] = useState(false)
  const [isModalSuccessTransferOpen, setIsModalSuccessTransferOpen] =
    useState(false)

  const { actions: actionsShop } = useShop()
  const { actions: actionsProfile } = useAccountProfile()
  const { actions: actionTheme } = useTheme()
  // eslint-disable-next-line
  const { t } = useTranslation()
  const location = useLocation()

  const history = useHistory()
  const { page, pageSize, setPage, setPageSize } = useQueryState()
  const {
    actions: actionsCommon,
    data: { allTEMAs },
  } = useCommon()
  const {
    actions,
    data: { itemDetail, isLoading },
  } = useItem()
  const [dataHistories, setDataHistories] = useState([])
  const [totalData, setTotalData] = useState(0)

  const [maxBalance, setMaxBalance] = useState(0)

  // const [loadBalance, setLoadbalence] = useState(false)
  useEffect(() => {
    if (user && itemDetail) {
      // setLoadbalence(true)
      loadBalanceItem()
    }
  }, [itemDetail])
  const [checkBonusItem, setCheckBonusItem] = useState(false)
  useEffect(() => {
    if (itemDetail?.quality === 'B' || itemDetail?.is_free === true) {
      setCheckBonusItem(true)
    } else {
      setCheckBonusItem(false)
    }
  }, [itemDetail])
  const loadBalanceItem = async () => {
    const balance = await getBalanceItem(
      itemDetail?.addressContract,
      user.address_wallet,
      itemDetail?.nft_id,
    )
    if (
      itemDetail?.item_type === 'Enhance_Item_Coach' ||
      itemDetail?.item_type === 'Enhance_Item_Crystal'
    ) {
      setMaxBalance(1)
    } else {
      setMaxBalance(Number(balance))
    }
  }

  // useEffect(() => {
  //   setLoadbalence(false)
  // }, [maxBalance])

  const columns = [
    {
      title: t('monsterDetail.trading.event'),
      dataIndex: 'event',
      key: 'event',
      align: 'center',
      render: (data) => {
        return <div>{data}</div>
      },
    },
    {
      title: t('monsterDetail.trading.from'),
      dataIndex: 'from',
      key: 'from',
      align: 'center',
      render: (data) => {
        let link = `${configs.NETWORK.scan}address/${data}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            style={{ color: '#40A9FF' }}
          >
            {convertAddressToProperFormat(formatAddress(data))}
          </a>
        )
      },
    },
    {
      title: t('monsterDetail.trading.to'),
      dataIndex: 'to',
      key: 'to',
      align: 'center',
      render: (data) => {
        let link = `${configs.NETWORK.scan}address/${data}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            style={{ color: '#40A9FF' }}
          >
            {convertAddressToProperFormat(formatAddress(data))}
          </a>
        )
      },
    },
    {
      title: t('monsterDetail.trading.price'),
      dataIndex: 'price',
      align: 'center',
      key: 'price',
      render: (data) => {
        return (
          <div>
            <Row gutter={4}>
              <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
                {formatNumber(data)}
              </Col>
              <Col
                span={12}
                style={{ display: 'flex', justifyContent: 'start' }}
              >
                OAS
              </Col>
            </Row>
          </div>
        )
      },
    },
    {
      title: t('monsterDetail.trading.amount'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
      render: (data) => {
        return <div>{data}</div>
      },
    },
    {
      // title: t('monsterDetail.trading.date'),
      title: (
        <>
          {t('monsterDetail.trading.date')} (UTC{' '}
          {userUtcOffset >= 0 ? `+${userUtcOffset}` : userUtcOffset})
        </>
      ),
      dataIndex: 'created_at',
      key: 'date',
      align: 'center',
      render: (data) => {
        // return (
        //   <div>
        //     {TimeHelper(Number(data) * 1000, {
        //       format: 'YYYY-MM-DD HH:mm:ss',
        //     })}
        //   </div>
        // )
        return dayjs(data * 1000).format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      title: t('monsterDetail.trading.txHash'),
      dataIndex: 'txHash',
      key: 'txHash',
      align: 'center',
      render: (data) => {
        const link = `${configs.NETWORK.scan}tx/${data}`
        return (
          <a
            style={{ color: '#40A9FF' }}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatTransaction(data)}
          </a>
        )
      },
    },
  ]

  const handleSell = async (tokenID, price, quantity) => {
    try {
      // eslint-disable-next-line
      console.log({ tokenID, price, quantity })
      setLoadingModal(true)
      if (itemDetail?.quality === 'SHOP') {
        const data = {
          address: user?.address_wallet,
          itemId: tokenID,
          amount: Number(quantity),
        }
        actionsShop.sellItemShop(data, (res) => {
          if (res?.txid) {
            setLoadingModal(false)
            handleCancelShop()
            setTxHash(res?.txid)
            showModalSuccessShop()
            actionsProfile.getAccountProfile((res) => {
              actionTheme.updateInfoUser({
                ...res?.player_info,
              })
            })
          }
        })
      } else if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        const {
          contract,
          iface,
          address: addressContract,
        } = getTypeItem(itemDetail?.type)

        let allowance = await contract.isApprovedForAll(
          user.address_wallet,
          configs.ADDRESS_MARKETPLACE,
        )
        if (allowance) {
          sell(addressContract, tokenID, price, quantity)
        } else {
          let data = {
            from: providerOAS.accounts[0],
            data: iface.encodeFunctionData('setApprovalForAll', [
              configs.ADDRESS_MARKETPLACE,
              true,
            ]),
            to: addressContract,
            nonce: nonce,
            //gasLimit: 21000,
          }

          const signedTransaction = await providerOAS.request({
            method: 'eth_signTransaction',
            params: [data],
          })

          const transaction = await ethersWeb3Provider.sendTransaction(
            String(signedTransaction),
          )
          checkTransactionConfirmation(transaction?.hash).then((r) => {
            if (r === 'confirmed') {
              // eslint-disable-next-line
              console.log('Approve:', r, transaction?.hash)
              sell(addressContract, tokenID, price, quantity)
            }
          })
        }
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        const {
          contract,
          iface,
          address: addressContract,
        } = getTypeItem(itemDetail?.type)

        let allowance = await contract.isApprovedForAll(
          user.address_wallet,
          configs.ADDRESS_MARKETPLACE,
        )

        if (allowance) {
          sell(addressContract, tokenID, price, quantity)
        } else {
          let nonce = await sdk.getProvider().request({
            method: 'eth_getTransactionCount',
            params: [user.address_wallet, 'latest'],
          })
          sdk
            .getProvider()
            .request({
              method: 'eth_sendTransaction',
              params: [
                {
                  from: user.address_wallet,
                  data: iface.encodeFunctionData('setApprovalForAll', [
                    configs.ADDRESS_MARKETPLACE,
                    true,
                  ]),
                  to: addressContract,
                  nonce,
                },
              ],
            })
            .then((txHash) => {
              checkTransactionConfirmation(txHash).then((r) => {
                if (r === 'confirmed') {
                  // eslint-disable-next-line
                  console.log('Approve:', r, txHash)
                  sell(addressContract, tokenID, price, quantity)
                }
              })
            })
            .catch((error) => {
              setLoadingModal(false)
              // eslint-disable-next-line
              console.error(error)
            })
        }
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
      setLoadingModal(false)
    }
  }

  const transactionQueue = new Map();

  const getTransactionQueue = (address) => {
    if (!transactionQueue.has(address)) {
      transactionQueue.set(address, Promise.resolve());
    }
    return transactionQueue.get(address);
  };

  const sell = async (contractAddress, tokenID, price, quantity) => {
    try {
      setLoadingModal(true);

      const priceInWei = toWei(price);

      if (localStorage.getItem('wallet') === 'OAS') {
        const signer = ethersWeb3Provider.getSigner(providerOAS.accounts[0]);
        const address = providerOAS.accounts[0];

        const queue = getTransactionQueue(address);
        transactionQueue.set(address, queue.then(async () => {
          const nonce = await signer.getTransactionCount('pending');
          const gasPrice = await ethersWeb3Provider.getGasPrice(); // Lấy gasPrice từ provider
          console.log('Gas price (OAS):', gasPrice.toString());

          const data = {
            from: address,
            data: ifaceContractMarketplace.encodeFunctionData('createMarketItemSale', [
              contractAddress,
              tokenID,
              priceInWei,
              quantity,
            ]),
            to: configs.ADDRESS_MARKETPLACE,
            nonce,
            gasPrice, // Dùng gasPrice thay vì EIP-1559
          };

          console.log('Sell data (OAS):', data);

          const signedTransaction = await providerOAS.request({
            method: 'eth_signTransaction',
            params: [data],
          });

          const transaction = await ethersWeb3Provider.sendTransaction(String(signedTransaction));
          console.log('Transaction sent (OAS):', transaction.hash);

          const receipt = await ethersWeb3Provider.waitForTransaction(transaction.hash);
          if (receipt.status === 0) {
            console.error('Transaction reverted (OAS):', receipt);
            throw new Error('Transaction reverted on blockchain. Check receipt logs for details.');
          }

          console.log('Transaction confirmed (OAS):', transaction.hash);
          const order = await getOrderIdFromTxHash(transaction.hash);
          history.push(
            `${ROUTE.ITEM_DETAIL.SUB_PATH}/${location.pathname.slice(1).split('/')[1]}/${location.pathname.slice(1).split('/')[2]}/${order}`,
          );
          await actions?.getDetailItem(
            {
              nft_id: Number(location.pathname.slice(1).split('/')[4]),
              _id: location.pathname.slice(1).split('/')[1],
              owner_address: location.pathname.slice(1).split('/')[2],
              orderId: order,
            },
            () => { },
            () => { history.push('/pageNotFound'); },
          );
          setTxHash(transaction.hash);
          showModalSuccessSell();
        }).finally(() => setLoadingModal(false)));

        await transactionQueue.get(address);
      } else {
        const provider = sdk.getProvider();
        await sdk.connect();
        await changeNetworkInMetamask(provider, configs.NETWORK.chainId, setLoadingModal);
        const networkId = hexToNumberString(await provider.request({ method: 'eth_chainId', params: [] }));
        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) {
          setLoadingModal(false);
          alert(`Please switch to network ${configs.NETWORK.chainId} in MetaMask`);
          throw new Error('Wrong network');
        }

        const address = user.address_wallet;

        const queue = getTransactionQueue(address);
        transactionQueue.set(address, queue.then(async () => {
          const nonce = await provider.request({
            method: 'eth_getTransactionCount',
            params: [address, 'pending'],
          });

          const gasPrice = await provider.request({
            method: 'eth_gasPrice',
            params: [],
          });
          console.log('Gas price (MetaMask):', gasPrice);

          const data = {
            from: address,
            data: ifaceContractMarketplace.encodeFunctionData('createMarketItemSale', [
              contractAddress,
              tokenID,
              priceInWei,
              quantity,
            ]),
            to: configs.ADDRESS_MARKETPLACE,
            nonce,
            gasPrice, // Dùng gasPrice thay vì EIP-1559
          };

          console.log('Sell data (MetaMask):', data);

          let gasEstimate;
          try {
            gasEstimate = await provider.request({
              method: 'eth_estimateGas',
              params: [data],
            });
            console.log('Gas estimate:', gasEstimate);
            data.gasLimit = ethers.BigNumber.from(gasEstimate).mul(120).div(100);
          } catch (gasError) {
            console.error('Gas estimation failed:', gasError);
            throw new Error('Unable to estimate gas. Transaction may revert.');
          }

          let txHash;
          try {
            txHash = await provider.request({
              method: 'eth_sendTransaction',
              params: [data],
            });
            console.log('Transaction sent (MetaMask):', txHash);
          } catch (sendError) {
            console.error('Send transaction error:', sendError);
            throw new Error('Failed to send transaction: ' + sendError.message);
          }

          let receipt = null;
          for (let i = 0; i < 10; i++) {
            receipt = await provider.request({
              method: 'eth_getTransactionReceipt',
              params: [txHash],
            });
            if (receipt) break;
            await new Promise(resolve => setTimeout(resolve, 1000));
          }

          if (receipt && receipt.status === '0x0') {
            console.error('Transaction reverted (MetaMask):', receipt);
            throw new Error('Transaction reverted on blockchain. Check receipt logs for details.');
          }

          if (!receipt) {
            console.warn('Receipt not found after 10 attempts. Transaction may still be pending.');
          }

          console.log('Transaction confirmed (MetaMask):', txHash);
          const order = await getOrderIdFromTxHash(txHash);
          history.push(
            `${ROUTE.ITEM_DETAIL.SUB_PATH}/${location.pathname.slice(1).split('/')[1]}/${location.pathname.slice(1).split('/')[2]}/${order}`,
          );
          await actions?.getDetailItem(
            {
              nft_id: Number(location.pathname.slice(1).split('/')[4]),
              _id: location.pathname.slice(1).split('/')[1],
              owner_address: location.pathname.slice(1).split('/')[2],
              orderId: order,
            },
            () => { },
            () => { history.push('/pageNotFound'); },
          );
          setTxHash(txHash);
          showModalSuccessSell();
        }).finally(() => setLoadingModal(false)));

        await transactionQueue.get(address);
      }
    } catch (error) {
      console.error('Sell error:', {
        message: error.message,
        code: error.code,
        data: error.data,
        stack: error.stack,
      });
      setLoadingModal(false);
      throw error;
    }
  };
  const handleBuy = async () => {
    try {
      setLoadingModal(true)

      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData('buyItem', [
            itemDetail?.orderId,
          ]),
          to: configs.ADDRESS_MARKETPLACE,
          value: numberToHex(toWei(itemDetail?.price)),
          nonce: nonce,
          //gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            history.push(
              `${ROUTE.ITEM_DETAIL.SUB_PATH}/${location.pathname.slice(1).split('/')[1]
              }/${location.pathname.slice(1).split('/')[2]}`,
            )
            actions?.getDetailItem(
              {
                nft_id: Number(location.pathname.slice(1).split('/')[4]),
                _id: location.pathname.slice(1).split('/')[1],
                owner_address: location.pathname.slice(1).split('/')[2],
                orderId: undefined,
              },
              () => { },
              () => {
                history.push('/pageNotFound')
              },
            )
            setLoadingModal(false)
            handleCancelBuy()
            setTxHash(transaction?.hash)
            showModalSuccessBuy()
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData('buyItem', [
                  itemDetail?.orderId,
                ]),
                to: configs.ADDRESS_MARKETPLACE,
                value: numberToHex(toWei(itemDetail?.price)),
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                history.push(
                  `${ROUTE.ITEM_DETAIL.SUB_PATH}/${location.pathname.slice(1).split('/')[1]
                  }/${location.pathname.slice(1).split('/')[2]}`,
                )
                actions?.getDetailItem(
                  {
                    nft_id: Number(location.pathname.slice(1).split('/')[4]),
                    _id: location.pathname.slice(1).split('/')[1],
                    owner_address: location.pathname.slice(1).split('/')[2],
                    orderId: undefined,
                  },
                  () => { },
                  () => {
                    history.push('/pageNotFound')
                  },
                )
                setLoadingModal(false)
                handleCancelBuy()
                setTxHash(txHash)
                showModalSuccessBuy()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }

  const handleCancelSell = async () => {
    try {
      setLoadingModal(true)

      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData(
            'cancelMarketItemSale',
            [itemDetail?.orderId],
          ),
          to: configs.ADDRESS_MARKETPLACE,
          nonce: nonce,
          //gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            history.push(
              `${ROUTE.ITEM_DETAIL.SUB_PATH}/${location.pathname.slice(1).split('/')[1]
              }/${location.pathname.slice(1).split('/')[2]}`,
            )
            actions?.getDetailItem(
              {
                nft_id: Number(location.pathname.slice(1).split('/')[4]),
                _id: location.pathname.slice(1).split('/')[1],
                owner_address: location.pathname.slice(1).split('/')[2],
                orderId: undefined,
              },
              () => { },
              () => {
                history.push('/pageNotFound')
              },
            )
            setLoadingModal(false)
            handleCancel()
            setTxHash(transaction?.hash)
            showModalSuccessCancelSell()
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData(
                  'cancelMarketItemSale',
                  [itemDetail?.orderId],
                ),
                to: configs.ADDRESS_MARKETPLACE,
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                history.push(
                  `${ROUTE.ITEM_DETAIL.SUB_PATH}/${location.pathname.slice(1).split('/')[1]
                  }/${location.pathname.slice(1).split('/')[2]}`,
                )
                actions?.getDetailItem(
                  {
                    nft_id: Number(location.pathname.slice(1).split('/')[4]),
                    _id: location.pathname.slice(1).split('/')[1],
                    owner_address: location.pathname.slice(1).split('/')[2],
                    orderId: undefined,
                  },
                  () => { },
                  () => {
                    history.push('/pageNotFound')
                  },
                )
                setLoadingModal(false)
                handleCancel()
                setTxHash(txHash)
                showModalSuccessCancelSell()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }
  const handleTransferNFT = async (recipientAddress, quantity) => {
    try {
      setLoadingModal(true)

      const {
        contract,
        iface,
        address: addressContract,
      } = getTypeItem(itemDetail?.type)

      if (!iface) {
        throw new Error(`Invalid item type: ${itemDetail?.type}`)
      }

      // Xác định loại contract (ERC-721 hay ERC-1155)
      const isERC1155 =
        itemDetail?.type === 'Regeneration_Hash' ||
        itemDetail?.type === 'Regeneration_Item' ||
        itemDetail?.type === 'Training_Item' ||
        itemDetail?.type === 'Enhance_Item' ||
        itemDetail?.type === 'Fusion_Item'

      const isERC721 =
        itemDetail?.type === 'Enhance_Item_Crystal' ||
        itemDetail?.type === 'Enhance_Item_Coach'

      let functionSignature
      let args

      if (isERC1155) {
        functionSignature =
          'safeTransferFrom(address,address,uint256,uint256,bytes)'
        args = [
          user.address_wallet,
          recipientAddress,
          itemDetail?.nft_id,
          quantity,
          '0x',
        ]
      } else if (isERC721) {
        functionSignature = 'safeTransferFrom(address,address,uint256)'
        args = [user.address_wallet, recipientAddress, itemDetail?.nft_id]
      } else {
        throw new Error(`Unsupported item type: ${itemDetail?.type}`)
      }

      // Kiểm tra hàm có trong ABI không
      const availableFunctions = Object.keys(iface.functions)
      if (!availableFunctions.includes(functionSignature)) {
        throw new Error(
          `ABI does not support ${functionSignature} for type ${itemDetail?.type}`,
        )
      }

      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: iface.encodeFunctionData(functionSignature, args),
          to: addressContract,
          nonce: nonce,
          //gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            setLoadingModal(false)
            handleCancelTransferNFT()
            setTxHash(transaction?.hash)
            showModalSuccessTransfer()

            actions?.getDetailItem(
              {
                nft_id: Number(location.pathname.slice(1).split('/')[4]),
                _id: location.pathname.slice(1).split('/')[1],
                owner_address: location.pathname.slice(1).split('/')[2],
                orderId: undefined,
              },
              () => { },
              () => {
                history.push('/pageNotFound')
              },
            )
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return

        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })

        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: iface.encodeFunctionData(functionSignature, args),
                to: addressContract,
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                setLoadingModal(false)
                handleCancelTransferNFT()
                setTxHash(txHash)
                showModalSuccessTransfer()

                actions?.getDetailItem(
                  {
                    nft_id: Number(location.pathname.slice(1).split('/')[4]),
                    _id: location.pathname.slice(1).split('/')[1],
                    owner_address: location.pathname.slice(1).split('/')[2],
                    orderId: undefined,
                  },
                  () => { },
                  () => {
                    history.push('/pageNotFound')
                  },
                )
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            console.error(error)
          })
      }
    } catch (error) {
      console.error(error)
      setLoadingModal(false)
    }
  }
  useEffect(() => {
    actionsCommon.getTema()
  }, [txHash])
  useEffect(() => {
    const params = {
      nft_id: Number(location.pathname.slice(1).split('/')[4]),
      _id: location.pathname.slice(1).split('/')[1],
      owner_address: location.pathname.slice(1).split('/')[2],
      item_type: location.pathname.slice(1).split('/')[1],
    }

    const orderId = location.pathname.slice(1).split('/')[3]
    if (orderId !== 'undefined') {
      params.orderId = orderId
    }
    actions?.getDetailItem(
      params,
      () => { },
      () => {
        history.push('/pageNotFound')
      },
    )
  }, [])

  useEffect(() => {
    if (
      itemDetail.tokenId ||
      itemDetail.nft_id ||
      // eslint-disable-next-line
      (itemDetail.tokenId || itemDetail.nft_id) == 0
    ) {
      actionsCommon.getHistoriesToken(
        {
          addressContract: itemDetail?.addressContract,
          tokenId: itemDetail.tokenId || itemDetail.nft_id,
          page: page,
        },
        async (res) => {
          let data = await Promise.all(
            res.data.map((temp, index) => {
              return {
                key: index,
                event:
                  temp?.type === 'OrderCreated'
                    ? 'For Sale'
                    : temp?.type === 'OrderSuccessful'
                      ? 'Buy'
                      : undefined,
                time: temp?.timestamp,
                from: temp?.from,
                to: temp?.to,
                price: fromWei(temp?.priceInWei),
                amount: temp.amount,
                txHash: temp?.transactionHash,
                created_at: temp?.timestamp,
              }
            }),
          )
          setTotalData(res?.total)
          setDataHistories(data)
        },
      )
    }
  }, [txHash, page, itemDetail])

  const loadBalanceOAS = () => {
    getBalanceOAS(user.address_wallet).then((res) => {
      setBalanceOAS(res)
    })
  }

  useEffect(() => {
    if (user && user !== null) {
      loadBalanceOAS()
    }
  }, [user, txHash])

  const handleShare = () => {
    setIsModalSuccessSellOpen(false)
  }

  const handleCancelSuccessSell = () => {
    setIsModalSuccessSellOpen(false)
  }

  const showModalSell = (box) => {
    if (box?.quality === 'SHOP') {
      setIsModalOpenShop(true)
    } else {
      setIsModalSellOpen(true)
    }
  }

  const showModalSuccessSell = () => {
    setIsModalSuccessSellOpen(true)
  }

  const showModalConnect = () => {
    setIsModalConnectOpen(true)
  }

  const handleCancelConnect = () => {
    setIsModalConnectOpen(false)
  }

  const showModalCheckout = () => {
    if (!user) {
      showModalConnect()
      return
    }
    setIsModalOpenBuy(true)
  }

  const showModalCancelSell = () => {
    setIsModalOpenCancel(true)
  }
  const showModalTransferNFT = () => {
    setIsModalTransferNFT(true)
  }
  const handleCancelTransferNFT = () => {
    setIsModalTransferNFT(false)
  }

  const handleCancelBuy = () => {
    setIsModalOpenBuy(false)
  }

  const handleCancel = () => {
    setIsModalOpenCancel(false)
  }

  const handleCancelModalSell = () => {
    setIsModalSellOpen(false)
  }

  const handleCancelShop = () => {
    setIsModalOpenShop(false)
  }
  const showModalSuccessCancelSell = () => {
    setIsModalSuccessOpenCancelSell(true)
  }

  const handleShareCancelSell = () => {
    setIsModalSuccessOpenCancelSell(false)
  }

  const handleCancelSuccessCancelSell = () => {
    setIsModalSuccessSellOpen(false)
    setIsModalSuccessOpenCancelSell(false)
  }
  const showModalSuccessShop = () => {
    setIsModalSuccessOpenShop(true)
  }

  const handleCancelSuccessShop = () => {
    setIsModalSuccessOpenShop(false)
  }

  const showModalSuccessBuy = () => {
    setIsModalSuccessOpenBuy(true)
  }

  const handleShareBuy = () => {
    setIsModalSuccessOpenBuy(false)
  }

  const handleCancelSuccessBuy = () => {
    setIsModalSuccessOpenBuy(false)
  }

  const showModalSuccessTransfer = () => {
    setIsModalSuccessTransferOpen(true)
  }
  const handleTransferSuccess = () => {
    setIsModalSuccessTransferOpen(false)
  }

  const ButtonItem = () => {
    if (itemDetail?.orderId) {
      if (
        itemDetail?.seller?.toLowerCase() ===
        user?.address_wallet?.toLowerCase()
      ) {
        return (
          <Button
            className="blockchain__button"
            onClick={() => {
              showModalCancelSell()
            }}
          >
            {t('cancel')}
          </Button>
        )
      } else {
        return (
          <Button
            className="blockchain__button"
            onClick={() => {
              showModalCheckout()
            }}
          >
            {user?.player_language === 'ja' ? '買う' : 'Buy Now'}
          </Button>
        )
      }
    } else {
      return (
        <>
          {checkBonusItem ? (
            <Popover
              content={
                <>
                  {itemDetail?.is_free === true
                    ? t('messageCheckFreeItem')
                    : t('messageCheckBonus')}
                </>
              }
            >
              <Button
                className=" btnCheckSell blockchain__button"
                disabled={checkBonusItem}
              >
                {t('notsell')}
              </Button>
            </Popover>
          ) : (
            <Button
              className="blockchain__button"
              // loading={loadBalance}
              onClick={() => {
                showModalSell(itemDetail)
              }}
            >
              {t('sell')}
            </Button>
          )}
        </>
      )
    }
  }
  function getItemGraphic(itemId) {
    // Kiểm tra điều kiện đặc biệt trước khi xử lý
    if (itemId === 'UPA 1912') {
      // eslint-disable-next-line
      itemId = 'UPA_1912'
    }
    if (itemId === 'UPA Gorem') {
      // eslint-disable-next-line
      itemId = 'UPA_Gorem'
    }
    if (itemId === 'UPA AC') {
      // eslint-disable-next-line
      itemId = 'UPA_AC'
    }
    if (itemId === 'UPA ODEN') {
      // eslint-disable-next-line
      itemId = 'UPA_ODEN'
    }
    if (itemId === 'UPA Ossan') {
      // eslint-disable-next-line
      itemId = 'UPA_Ossan'
    }
    if (itemId === 'UPA Villain') {
      // eslint-disable-next-line
      itemId = 'UPA_Villain'
    }
    if (itemId === 'Yamato T Shirt') {
      // eslint-disable-next-line
      itemId = 'YamatoT_Shirt'
    }
    if (itemId === 'Sound Horse') {
      // eslint-disable-next-line
      itemId = 'Soundhorse'
    }

    // Loại bỏ khoảng trắng
    let formattedItemId = itemId?.replace(/\s+/g, '')

    for (let i = 0; i < getImageHash.length; i++) {
      let formattedImageHashItemId = getImageHash[i].itemId.replace(/\s+/g, '')

      if (formattedImageHashItemId === formattedItemId) {
        return getImageHash[i].itemGraphic
      }
    }
    return null
  }
  return (
    <BodyCommon>
      {isLoading && <LoadingItemDetail />}
      {!isLoading && (
        <div className="relative container__common mx-auto z-10 top-[84px]">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-0 sm:gap-4 pt-5 sm:pt-12">
            <div className="flex flex-col mb-3 sm:mb-0">
              <div className="relative card__detail w-[93.2%] h-[300px] md:w-[93.2%] md:h-[656px] flex items-center justify-center">
                <div className="relative flex items-center justify-center h-full w-full">
                  <div className="relative flex flex-col w-[93%] h-[93%] justify-between">
                    <div
                      className="h-full flex flex-col items-center p-[10px] text-[18px] justify-center bg-cover bg-no-repeat"
                      style={{
                        background:
                          itemDetail?.item_type === 'Enhance_Item_Coach'
                            ? `url('/imgs/coach/${itemDetail?.coach_value_type}.jpg') no-repeat center/cover`
                            : getbgQuality(itemDetail?.quality),
                        clipPath:
                          'polygon(97% 100%, 100% 97%, 100% 3%, 97% 0%, 3% 0%, 0% 3%, 0% 97%, 3% 100%)',
                      }}
                    >
                      <div className="relative">
                        <Image
                          // className="relative card__detail_img w-[80%] h-[80%] max-h-[350px] object-contain ease-in-out duration-300 z-50"
                          className="w-[80%] h-[80%] max-h-[350px] object-contain"
                          style={{
                            width: 'auto',
                            height: 'auto',
                            maxWidth: '100%',
                            maxHeight: '350px',
                          }}
                          src={
                            itemDetail?.type === 'Regeneration_Hash'
                              ? itemDetail?.name ===
                                'Regeneration Hash [Random]'
                                ? `${process.env.REACT_APP_BASE_URL_IMAGE}/public/image/image/hash/Regeneration_Hash_Random.png`
                                : itemDetail?.name === 'Hash Fragment'
                                  ? `${process.env.REACT_APP_BASE_URL_IMAGE}/public/image/image/items/Hash Fragment.png`
                                  : `${process.env.REACT_APP_BASE_URL_IMAGE
                                  }/public/image/image/hash/${getItemGraphic(
                                    itemDetail?.name,
                                  )}`
                              : itemDetail?.item_type === 'Enhance_Item_Crystal'
                                ? `/imgs/crystal/${getRankbyValue(
                                  itemDetail?.crystal_rank,
                                )}_${getMaxParameter(
                                  itemDetail?.crystal_health,
                                  itemDetail?.crystal_strength,
                                  itemDetail?.crystal_intelligent,
                                  itemDetail?.crystal_dexterity,
                                  itemDetail?.crystal_agility,
                                  itemDetail?.crystal_vitality,
                                )}.svg`
                                : itemDetail?.item_type === 'Enhance_Item_Coach'
                                  ? `${process.env.REACT_APP_BASE_URL_IMAGE}/public/image/gif/${itemDetail?.monster?.monster_script_id}.gif`
                                  : `${process.env.REACT_APP_BASE_URL_IMAGE
                                  }/public/image/image/items/${getNameItemV2(
                                    itemDetail?.item_type,
                                  )}.png`
                          }
                          alt=""
                          preview={false}
                          loading="lazy"
                        />
                        {itemDetail?.item_type !== 'Enhance_Item_Coach' &&
                          itemDetail?.item_type !== 'Enhance_Item_Crystal' && (
                            <>
                              <div
                                className={`absolute ${itemDetail?.item_type ===
                                    'REGENERATION_HASH_OOO_R'
                                    ? 'left-[-2.75rem] bottom-[-40%]'
                                    : itemDetail?.item_type ===
                                      'REGENERATION_HASH_RANDOM_R'
                                      ? 'left-[-0.75rem] bottom-[-40%]'
                                      : 'left-1 bottom-[-20%]'
                                  }`}
                              >
                                <div>{t('quantity')}</div>
                              </div>
                              <div
                                className={`absolute ${itemDetail?.item_type ===
                                    'REGENERATION_HASH_OOO_R'
                                    ? 'right-[-2.75rem] bottom-[-40%]'
                                    : itemDetail?.item_type ===
                                      'REGENERATION_HASH_RANDOM_R'
                                      ? 'right-[-0.75rem] bottom-[-40%]'
                                      : 'right-1 bottom-[-20%]'
                                  }`}
                              >
                                {maxBalance}
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Card className="mt-3">
                <Row>
                  <Col span={12} className="text-[16px]">
                    <div className="mb-3 h-[25px]">
                      {t('farmDetail.ownerAddress')}
                    </div>
                    <div className="mb-3 h-[25px]">
                      {t('farmDetail.contractAddress')}
                    </div>
                    <div className="mb-3 h-[25px]">
                      {t('farmDetail.tokenID')}
                    </div>
                  </Col>
                  <Col span={12} className="text-end">
                    <div className="mb-3 h-[25px]">
                      <a
                        style={{ color: '#40A9FF' }}
                        href={`${configs.NETWORK.scan}address/${itemDetail?.orderId
                            ? itemDetail?.seller
                            : itemDetail?.owner_address
                          }`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {convertAddressToProperFormat(
                          formatAddress(
                            itemDetail?.orderId
                              ? itemDetail?.seller
                              : itemDetail?.owner_address,
                          ),
                        )}
                      </a>
                    </div>

                    <div className="mb-3 h-[25px]">
                      {/* {formatAddress(itemDetail?.addressContract)} */}
                      <a
                        style={{ color: '#40A9FF' }}
                        href={`${configs.NETWORK.scan}address/${itemDetail?.addressContract}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {formatAddress(itemDetail?.addressContract)}
                      </a>
                    </div>
                    <div className="mb-3 h-[25px]">
                      <a
                        style={{ color: '#40A9FF' }}
                        href={`${configs.NETWORK.scan}token/${itemDetail?.addressContract}/instance/${itemDetail?.nft_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {itemDetail?.id ?? itemDetail?.nft_id}
                      </a>
                    </div>
                  </Col>
                </Row>
              </Card>
            </div>
            <div className="col-span-2">
              <div className="flex items-center">
                <img
                  className="mr-2 w-[42px] h-[42px] object-contain	"
                  src={`${process.env.PUBLIC_URL}/svg/qualityTag/${itemDetail?.quality}.svg`}
                  alt=""
                />
                {itemDetail?.item_type === 'REGENERATION_HASH_OOO_R' ? (
                  <>
                    <div className="title__monster_detail">
                      {/* {getNameItemV3(itemDetail?.item_type)?.name.replace(
                        '〇〇〇',
                        formatItemName(itemDetail.name),
                      )} */}
                      {'Regeneration Hash [name]'.replace(
                        'name',
                        itemDetail.name,
                      )}
                    </div>
                  </>
                ) : itemDetail?.item_type === 'Enhance_Item_Coach' ||
                  itemDetail?.item_type === 'Enhance_Item_Crystal' ? (
                  <>
                    <div className="title__monster_detail">
                      {itemDetail?.item_type === 'Enhance_Item_Crystal'
                        ? t('crystalItem', {
                          item: itemDetail?.name,
                        })
                        : itemDetail?.name}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="title__monster_detail">
                      {getNameItemV3(itemDetail?.item_type)?.name}{' '}
                    </div>
                  </>
                )}
              </div>
              <Card className="mt-3 mb-8">
                {itemDetail?.price ? (
                  <div>
                    <Row>
                      <Col span={12} className="flex items-center">
                        {itemDetail?.price && (
                          <div className="relative z-10 flex items-center">
                            <img
                              className="mr-2"
                              src={`${process.env.PUBLIC_URL}/imgs/coins/oasis.svg`}
                              alt=""
                              width={32}
                              height={32}
                            />
                            <div className="flex items-center">
                              <div className="flex text-[20px] sm:text-[32px] font-[700]">
                                {formatNumber(itemDetail?.price)}
                              </div>
                              <span className="text-[16px] sm:text-[20px] flex">
                                <div className="px-2"> ~ </div> $
                                {formatNumber(
                                  convertOasToUSD(
                                    itemDetail?.price,
                                    allTEMAs?.tema,
                                  ),
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                      </Col>
                      <Col span={12} className={`${'text-end'}`}>
                        <ButtonItem />{' '}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12} className="flex items-center">
                        <div className="mr-3">
                          {user?.player_language === 'ja'
                            ? '最低価格'
                            : 'Floor Price'}
                        </div>
                        <div className="relative z-10 flex items-center">
                          <img
                            className="mr-2"
                            src={`${process.env.PUBLIC_URL}/imgs/coins/oasis.svg`}
                            alt=""
                            width={22}
                            height={22}
                          />
                          <div className="flex items-center">
                            <div className="flex text-[14px] sm:text-[16px] font-[700]">
                              {formatNumber(itemDetail?.floorPrice)}
                            </div>
                            <span className="text-[10px] sm:text-[14px] flex">
                              <div className="px-2"> ~ </div> $
                              {formatNumber(
                                convertOasToUSD(
                                  itemDetail?.floorPrice,
                                  allTEMAs?.tema,
                                ),
                              )}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <>
                    <Row>
                      {user?.player_language === 'ja'
                        ? '最低価格'
                        : 'Floor Price'}
                    </Row>
                    <Row>
                      <Col span={12} className="flex items-center">
                        <div className="relative z-10 flex items-center">
                          <img
                            className="mr-2"
                            src={`${process.env.PUBLIC_URL}/imgs/coins/oasis.svg`}
                            alt=""
                            width={32}
                            height={32}
                          />
                          {/* <div className="flex items-center">
                            <div className="flex text-[20px] sm:text-[32px] font-[700]">
                              {formatNumber(itemDetail?.floorPrice)}
                            </div>
                            <span className="text-[16px] sm:text-[20px] flex">
                              <div className="px-2"> ~ </div> $
                              {formatNumber(
                                convertOasToUSD(
                                  itemDetail?.floorPrice,
                                  allTEMAs?.tema,
                                ),
                              )}
                            </span>
                          </div> */}
                          {itemDetail?.floorPrice ? (
                            <div className="flex items-center">
                              <div className="flex text-[20px] sm:text-[32px] font-[700]">
                                {formatNumber(itemDetail?.floorPrice)}
                              </div>
                              <span className="text-[16px] sm:text-[20px] flex">
                                <div className="px-2"> ~ </div> $
                                {formatNumber(
                                  convertOasToUSD(
                                    itemDetail?.floorPrice,
                                    allTEMAs?.tema,
                                  ),
                                )}
                              </span>
                            </div>
                          ) : (
                            <div className="text-[24px]">{t('noListing')}</div>
                          )}
                        </div>
                      </Col>
                      <Col span={12} className={`${'text-end'}`}>
                        <ButtonItem />
                      </Col>
                    </Row>
                  </>
                )}
              </Card>
              {itemDetail?.seller?.toLowerCase() !==
                user?.address_wallet?.toLowerCase() &&
                itemDetail?.quality !== 'B' &&
                itemDetail?.quality !== 'SHOP' &&
                itemDetail?.is_free !== true &&
                maxBalance > 0 && (
                  <Col
                    xl={24}
                    md={24}
                    sm={24}
                    xs={24}
                    className="text-end mb-[2rem]"
                  >
                    <Button
                      style={{
                        backgroundColor: 'rgba(24, 144, 255, 1)',
                        color: '#fff',
                      }}
                      className="h-[100%] hover:text-white"
                      onClick={() => showModalTransferNFT()}
                    >
                      {t('transferNFT')}
                    </Button>
                  </Col>
                )}
              <ParameterItem t={t} itemDetail={itemDetail} />
            </div>
          </div>
          {itemDetail?.quality === 'SHOP' ? (
            <></>
          ) : (
            <div className="mt-5 mb-20">
              <Datatable
                scroll={{ x: 1300 }}
                breadcrumb={
                  <div className="text-[24px] mb-5">
                    {t('monsterDetail.tradingHistory')}
                  </div>
                }
                columns={columns}
                dataSource={dataHistories}
                loading={false}
                metadata={{
                  perPage: pageSize,
                  setPerPage: setPageSize,
                  page,
                  setPage,
                  total: totalData === 0 ? 1 : totalData,
                }}
                showPagination
              />
            </div>
          )}
        </div>
      )}
      <SellToMKPModal
        action={{
          isModalOpen: isModalSellOpen,
          handleOk: handleSell,
          handleCancel: handleCancelModalSell,
        }}
        tokenID={itemDetail?.nft_id}
        t={t}
        loadingModal={loadingModal}
        // name={`${
        //   itemDetail?.item_type === 'REGENERATION_HASH_OOO_R'
        //     ? getNameItemV3(itemDetail?.item_type)?.name.replace(
        //         '〇〇〇',
        //         formatItemName(itemDetail.name),
        //       )
        //     : getNameItemV3(itemDetail?.item_type)?.name
        // } (#${itemDetail?.id ?? itemDetail?.nft_id})`}
        name={
          itemDetail?.item_type === 'REGENERATION_HASH_OOO_R'
            ? getNameItemV3(itemDetail?.item_type)?.name.replace(
              '〇〇〇',
              formatItemName(itemDetail.name),
            )
            : itemDetail?.item_type === 'Enhance_Item_Crystal'
              ? t('crystalItem', {
                item: itemDetail?.name,
              })
              : itemDetail?.item_type === 'Enhance_Item_Coach'
                ? itemDetail?.name
                : getNameItemV3(itemDetail?.item_type)?.name
        }
        // tema={tema}
        item={itemDetail}
        balance={balanceOAS}
        quanlityTotal={maxBalance}
      />
      <SellToShopModal
        action={{
          isModalOpen: isModalOpenShop,
          handleOk: handleSell,
          handleCancel: handleCancelShop,
        }}
        tokenID={itemDetail?.nft_id}
        t={t}
        loadingModal={loadingModal}
        // name={getNameItemV3(itemDetail?.item_type)?.name}
        name={
          itemDetail?.item_type === 'REGENERATION_HASH_OOO_R'
            ? getNameItemV3(itemDetail?.item_type)?.name.replace(
              '〇〇〇',
              formatItemName(itemDetail.name),
            )
            : itemDetail?.item_type === 'Enhance_Item_Crystal'
              ? t('crystalItem', {
                item: itemDetail?.name,
              })
              : itemDetail?.item_type === 'Enhance_Item_Coach'
                ? itemDetail?.name
                : getNameItemV3(itemDetail?.item_type)?.name
        }
        balance={balanceOAS}
        quanlityTotal={maxBalance}
      />
      <CheckoutModal
        action={{
          isModalOpen: isModalOpenBuy,
          handleOk: handleBuy,
          handleCancel: handleCancelBuy,
        }}
        t={t}
        box={itemDetail}
        loadingModal={loadingModal}
        tema={allTEMAs?.tema}
        balance={balanceOAS}
      />
      <ModalTransferNFT
        action={{
          isModalOpen: isModalTransferNFT,
          handleOk: (recipientAddress, quantity) =>
            handleTransferNFT(recipientAddress, quantity),
          handleCancel: handleCancelTransferNFT,
        }}
        t={t}
        tokenID={itemDetail?.nft_id}
        name={
          itemDetail?.item_type === 'REGENERATION_HASH_OOO_R'
            ? getNameItemV3(itemDetail?.item_type)?.name.replace(
              '〇〇〇',
              formatItemName(itemDetail.name),
            )
            : itemDetail?.item_type === 'Enhance_Item_Crystal'
              ? t('crystalItem', {
                item: itemDetail?.name,
              })
              : itemDetail?.item_type === 'Enhance_Item_Coach'
                ? itemDetail?.name
                : getNameItemV3(itemDetail?.item_type)?.name
        }
        quanlityTotal={maxBalance}
      />

      {/* itemDetail?.id ?? itemDetail?.nft_id */}
      <CancelSell
        action={{
          isModalOpen: isModalOpenCancel,
          handleOk: handleCancelSell,
          handleCancel: handleCancel,
        }}
        t={t}
        // name={`${getNameItemV3(itemDetail?.item_type)?.name}`}
        name={
          itemDetail?.item_type === 'REGENERATION_HASH_OOO_R'
            ? getNameItemV3(itemDetail?.item_type)?.name.replace(
              '〇〇〇',
              formatItemName(itemDetail.name),
            )
            : itemDetail?.item_type === 'Enhance_Item_Crystal'
              ? t('crystalItem', {
                item: itemDetail?.name,
              })
              : itemDetail?.item_type === 'Enhance_Item_Coach'
                ? itemDetail?.name
                : getNameItemV3(itemDetail?.item_type)?.name
        }
        loadingModal={loadingModal}
      // name={'Monster'}
      // tema={tema}
      />
      <TransactionCompletedSell
        action={{
          isModalOpen: isModalSuccessSellOpen,
          handleOk: handleShare,
          handleCancel: handleCancelSuccessCancelSell,
        }}
        t={t}
        // name={getNameItemV3(itemDetail?.item_type)?.name}
        name={
          itemDetail?.item_type === 'REGENERATION_HASH_OOO_R'
            ? getNameItemV3(itemDetail?.item_type)?.name.replace(
              '〇〇〇',
              formatItemName(itemDetail.name),
            )
            : itemDetail?.item_type === 'Enhance_Item_Crystal'
              ? t('crystalItem', {
                item: itemDetail?.name,
              })
              : itemDetail?.item_type === 'Enhance_Item_Coach'
                ? itemDetail?.name
                : getNameItemV3(itemDetail?.item_type)?.name
        }
        store={'Marketplace'}
        txHash={txHash}
      />
      <TransactionCompletedCancelSell
        action={{
          isModalOpen: isModalSuccessOpenCancelSell,
          handleOk: handleShareCancelSell,
          handleCancel: handleCancelSuccessCancelSell,
        }}
        t={t}
        // name={`${getNameItemV3(itemDetail?.item_type)?.name}`}
        name={
          itemDetail?.item_type === 'REGENERATION_HASH_OOO_R'
            ? getNameItemV3(itemDetail?.item_type)?.name.replace(
              '〇〇〇',
              formatItemName(itemDetail.name),
            )
            : itemDetail?.item_type === 'Enhance_Item_Crystal'
              ? t('crystalItem', {
                item: itemDetail?.name,
              })
              : itemDetail?.item_type === 'Enhance_Item_Coach'
                ? itemDetail?.name
                : getNameItemV3(itemDetail?.item_type)?.name
        }
        store={'Marketplace'}
        txHash={txHash}
      />
      <ModalTransferNFTSuccess
        action={{
          isModalOpen: isModalSuccessTransferOpen,
          handleCancel: handleTransferSuccess,
        }}
        t={t}
        name={
          itemDetail?.item_type === 'REGENERATION_HASH_OOO_R'
            ? getNameItemV3(itemDetail?.item_type)?.name.replace(
                '〇〇〇',
                formatItemName(itemDetail.name),
              )
            : itemDetail?.item_type === 'Enhance_Item_Crystal'
            ? t('crystalItem', {
                item: itemDetail?.name,
              })
            : itemDetail?.item_type === 'Enhance_Item_Coach'
            ? itemDetail?.name
            : getNameItemV3(itemDetail?.item_type)?.name
        }
        txHash={txHash}
      />
      <TransactionCompletedMarketplace
        action={{
          isModalOpen: isModalSuccessOpenBuy,
          handleOk: handleShareBuy,
          handleCancel: handleCancelSuccessBuy,
        }}
        t={t}
        // name={`${getNameItemV3(itemDetail?.item_type)?.name}`}
        name={
          itemDetail?.item_type === 'REGENERATION_HASH_OOO_R'
            ? getNameItemV3(itemDetail?.item_type)?.name.replace(
              '〇〇〇',
              formatItemName(itemDetail.name),
            )
            : itemDetail?.item_type === 'Enhance_Item_Crystal'
              ? t('crystalItem', {
                item: itemDetail?.name,
              })
              : itemDetail?.item_type === 'Enhance_Item_Coach'
                ? itemDetail?.name
                : getNameItemV3(itemDetail?.item_type)?.name
        }
        store={'Marketplace'}
        txHash={txHash}
      />
      <SelectWallet
        action={{
          isModalOpen: isModalConnectOpen,
          handleCancel: handleCancelConnect,
        }}
        t={t}
      />

      <TransactionCompletedSellShop
        action={{
          isModalOpen: isModalSuccessShopOpen,
          // handleOk: handleShare,
          handleCancel: handleCancelSuccessShop,
        }}
        t={t}
        // name={`${getNameItemV3(itemDetail?.item_type)?.name}`}
        name={
          itemDetail?.item_type === 'REGENERATION_HASH_OOO_R'
            ? getNameItemV3(itemDetail?.item_type)?.name.replace(
              '〇〇〇',
              formatItemName(itemDetail.name),
            )
            : itemDetail?.item_type === 'Enhance_Item_Crystal'
              ? t('crystalItem', {
                item: itemDetail?.name,
              })
              : itemDetail?.item_type === 'Enhance_Item_Coach'
                ? itemDetail?.name
                : getNameItemV3(itemDetail?.item_type)?.name
        }
        store={'Shop'}
        txHash={txHash}
      />
    </BodyCommon>
  )
}

export default ItemsDetail
