import { ArrowUpOutlined, FilterOutlined } from '@ant-design/icons'
import { Button, Col, Drawer, Dropdown, Pagination, Row, Spin } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { ifaceContractMarketplace } from '~/blockchain/contract'
import { ethersWeb3Provider, providerOAS } from '~/blockchain/oas'
import {
  ITEM_SORT_SALE_USER,
  ITEM_SORT_SALE_USER_JA,
  SCREEN_SIZE,
} from '~/common/constants'
// import { useQueryState } from '~/common/hooks/useQueryState'
import { ItemsCardMyAsset } from '~/components/shared-components/Card/ItemsCardMyAsset'
import { getNameItem, getNameItemV1, getNameItemV3 } from '~/helpers/ItemName'
import { useCommon } from '~/views/redux/hooks/useCommon'
import { useItem } from '~/views/redux/hooks/useItem'
import configs from '~/configs'
import {
  checkTransactionConfirmation,
  hexToNumberString,
} from '~/blockchain/provider'
import { changeNetworkInMetamask } from '~/blockchain/metamask'
import CancelSell from '~/components/Modals/CancelSell'
import TransactionCompletedCancelSell from '~/components/Modals/TransactionCompletedCancelSell'
import { NodataMonster } from '~/components/shared-components/NoData/NodataMonster'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { useTranslation } from '~/common/hooks/useTranslation'
import BodyProfile from '~/components/shared-components/BodyProfile'
import { FilterItems } from '~/views/app/Items/ItemsDetail/components/FilterItems'
import { useSDK } from '@metamask/sdk-react'
import { useMediaQuery } from 'react-responsive'
import getImageHash from '~/assets/data/ImageHash.json'
import { formatItemName } from '~/helpers/common'
export const ItemSale = () => {
  const { sdk } = useSDK()
  const { t } = useTranslation()
  const [sort, setSort] = useState(
    JSON.parse(localStorage.getItem('ItemsMyCreated'))?.sort ||
      ITEM_SORT_SALE_USER[0],
  )
  const [typeSort, setTypeSort] = useState(
    JSON.parse(localStorage.getItem('ItemsMyCreated'))?.typeSort || '-1',
  )
  const {
    data: { user },
  } = useTheme()
  const [txHash, setTxHash] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoadingModal, setLoadingModal] = useState(false)
  const [nftActive, setNftActive] = useState()
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false)
  const {
    data: { allTEMAs },
    actions: actionsTema,
  } = useCommon()
  // Item
  // const [category, setCategory] = useState(['Enhance_Item'])
  const [category, setCategory] = useState(
    JSON.parse(localStorage.getItem('ItemsMyCreated'))?.category || [],
  )
  const [effect, setEffect] = useState(
    JSON.parse(localStorage.getItem('ItemsMyCreated'))?.effect || [],
  )
  const [quality, setQuality] = useState(
    JSON.parse(localStorage.getItem('ItemsMyCreated'))?.quality || [],
  )
  const [mainSeedFilterRegeneration, setMainSeedFilterRegeneration] = useState(
    JSON.parse(localStorage.getItem('ItemsMyCreated'))
      ?.mainSeedFilterRegeneration || [],
  )
  const [pageSize, setPageSize] = useState(
    JSON.parse(localStorage.getItem('ItemsMyCreated'))?.pageSize || 10,
  )
  const [page, setPage] = useState(
    JSON.parse(localStorage.getItem('ItemsMyCreated'))?.page || 1,
  )
  const showModalCancelSell = () => {
    setIsModalOpen(true)
  }

  const showModalSuccess = () => {
    setIsModalSuccessOpen(true)
  }

  const handleShare = () => {
    setIsModalSuccessOpen(false)
  }

  const handleCancelSuccess = () => {
    setIsModalSuccessOpen(false)
  }

  const handleCancelSell = async () => {
    try {
      setLoadingModal(true)
      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData(
            'cancelMarketItemSale',
            [nftActive?.orderId],
          ),
          to: configs.ADDRESS_MARKETPLACE,
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            handleCancel()
            setTxHash(transaction?.hash)
            showModalSuccess()
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData(
                  'cancelMarketItemSale',
                  [nftActive?.orderId],
                ),
                to: configs.ADDRESS_MARKETPLACE,
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                handleCancel()
                setTxHash(txHash)
                showModalSuccess()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setLoadingModal(false)
  }

  const {
    actions,
    data: { allItemUser, totalItemUser, isLoading },
  } = useItem()
  // const { page, pageSize, setPage, setPageSize } = useQueryState()

  const handleChangeSort = (e) => {
    setSort(ITEM_SORT_SALE_USER[e.key])
  }
  const onChangePage = (page) => {
    setPage(page)
  }
  const onChangePageSize = (current, size) => {
    setPageSize(size)
  }
  const handleChangeTypeSort = () => {
    if (typeSort === '-1') {
      setTypeSort('1')
    } else if (typeSort === '1') {
      setTypeSort('-1')
    }
  }

  //Refresh data
  const refreshData = useCallback(() => {
    const params = {
      page,
      pageSize: pageSize,
      wallet_address: user.address_wallet,
      sort_by_field: sort.value,
      sort_by_dir: typeSort,
      category: category,
      effect: effect,
      quality: quality,
      main_seed: mainSeedFilterRegeneration,
    }
    actions.getAllItemSaleUser(params)
  }, [
    page,
    pageSize,
    sort,
    typeSort,
    category,
    effect,
    quality,
    mainSeedFilterRegeneration,
  ])

  useEffect(() => {
    if (user) {
      refreshData()
      actionsTema.getTema()
    }
  }, [
    txHash,
    page,
    pageSize,
    sort,
    typeSort,
    category,
    effect,
    quality,
    mainSeedFilterRegeneration,
  ])
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  const [openFilterItem, setOpenFilterItem] = useState(false)
  const showDrawer = () => {
    setOpenFilterItem(true)
  }

  const onClose = () => {
    setOpenFilterItem(false)
  }
  const clearAll = () => {
    // setCategory(['Enhance_Item'])
    setCategory([])
    setEffect([])
    setQuality([])
    setMainSeedFilterRegeneration([])
  }
  useEffect(() => {
    if (page > 1) {
      onChangePage(1)
    }
  }, [
    txHash,
    pageSize,
    sort,
    typeSort,
    category,
    effect,
    quality,
    mainSeedFilterRegeneration,
  ])
  const pageFilter = JSON.parse(localStorage.getItem('ItemsMyCreated'))?.page
  useEffect(() => {
    if (pageFilter > 1) {
      setPage(pageFilter)
    }
  }, [pageFilter])

  function getItemGraphic(itemId) {
    // Kiểm tra điều kiện đặc biệt trước khi xử lý
    if (itemId === 'UPA 1912') {
      // eslint-disable-next-line
      itemId = 'UPA_1912'
    }
    if (itemId === 'UPA Gorem') {
      // eslint-disable-next-line
      itemId = 'UPA_Gorem'
    }
    if (itemId === 'UPA AC') {
      // eslint-disable-next-line
      itemId = 'UPA_AC'
    }
    if (itemId === 'UPA ODEN') {
      // eslint-disable-next-line
      itemId = 'UPA_ODEN'
    }
    if (itemId === 'UPA Ossan') {
      // eslint-disable-next-line
      itemId = 'UPA_Ossan'
    }
    if (itemId === 'UPA Villain') {
      // eslint-disable-next-line
      itemId = 'UPA_Villain'
    }
    if (itemId === 'Yamato T Shirt') {
      // eslint-disable-next-line
      itemId = 'YamatoT_Shirt'
    }
    if (itemId === 'Sound Horse') {
      // eslint-disable-next-line
      itemId = 'Soundhorse'
    }

    // Loại bỏ khoảng trắng
    let formattedItemId = itemId?.replace(/\s+/g, '')

    for (let i = 0; i < getImageHash.length; i++) {
      let formattedImageHashItemId = getImageHash[i].itemId.replace(/\s+/g, '')

      if (formattedImageHashItemId === formattedItemId) {
        return getImageHash[i].itemGraphic
      }
    }
    return null
  }
  return (
    <BodyProfile>
      <Row className={`${isMobile ? '' : 'mx-auto h-[100vh] filter_profile'}`}>
        <Col xl={6} md={6} sm={24} xs={24} className="relative">
          {isMobile ? (
            <></>
          ) : (
            <div className="flex w-full h-full">
              <FilterItems
                sort={sort}
                typeSort={typeSort}
                page={page}
                pageSize={pageSize}
                category={category}
                effect={effect}
                quality={quality}
                mainSeedFilterRegeneration={mainSeedFilterRegeneration}
                setCategory={setCategory}
                setEffect={setEffect}
                setQuality={setQuality}
                setMainSeedFilterRegeneration={setMainSeedFilterRegeneration}
                classCustom="filter__profile"
              />
            </div>
          )}
        </Col>
        <Col xl={18} md={18} sm={24} xs={24} className="relative">
          <div className="mx-auto z-10 pl-0 pt-[1.5rem] relative md:sticky top-0 sm:top-[104px] pb-12">
            <div className="text-[24px] mb-4 uppercase">
              {t('profile.menu.items')}
            </div>
            <div className="flex justify-between flex-wrap mb-4">
              <div className="text-[24px]">
                {t('countItem', {
                  total: totalItemUser,
                })}
                {totalItemUser > 1 ? t('more') : ''}
              </div>
              {isMobile ? (
                <>
                  <div className="flex items-center text-[16px] gap-2">
                    <Dropdown
                      className="flex items-center cursor-pointer sort__value"
                      menu={{
                        items:
                          user?.player_language === 'en'
                            ? ITEM_SORT_SALE_USER
                            : ITEM_SORT_SALE_USER_JA,
                        selectable: true,
                        onClick: handleChangeSort,
                      }}
                      placement="bottomRight"
                      trigger={['click']}
                    >
                      <Button className="flex bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[200px]">
                        <div className="title">
                          {' '}
                          {t(`common.filter.sort.${sort?.valueLanguage}`)}
                        </div>
                      </Button>
                    </Dropdown>

                    <Button
                      className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[44px]"
                      onClick={() => {
                        handleChangeTypeSort()
                      }}
                    >
                      <ArrowUpOutlined
                        style={{ fontSize: 18 }}
                        rotate={typeSort === '1' ? 0 : 180}
                      />
                    </Button>
                    <div>
                      <Button
                        type="primary"
                        onClick={showDrawer}
                        className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[100px]"
                      >
                        <FilterOutlined />
                        {t('filter')}
                      </Button>
                      <Drawer
                        title={t('filter')}
                        width={330}
                        placement="left"
                        onClose={onClose}
                        open={openFilterItem}
                        extra={
                          <div
                            className="text-[16px] text-[#FAAD14] cursor-pointer"
                            onClick={() => {
                              clearAll()
                            }}
                          >
                            {t('clearAll')}
                          </div>
                        }
                      >
                        <div className="flex w-full h-full">
                          <FilterItems
                            sort={sort}
                            typeSort={typeSort}
                            page={page}
                            pageSize={pageSize}
                            category={category}
                            effect={effect}
                            quality={quality}
                            mainSeedFilterRegeneration={
                              mainSeedFilterRegeneration
                            }
                            setCategory={setCategory}
                            setEffect={setEffect}
                            setQuality={setQuality}
                            setMainSeedFilterRegeneration={
                              setMainSeedFilterRegeneration
                            }
                            classCustom="filter__profile"
                          />
                        </div>
                      </Drawer>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex items-center text-[16px] gap-2">
                    <div> {t('sortBy')}</div>

                    <Dropdown
                      className="flex items-center cursor-pointer ml-3  sort__value"
                      menu={{
                        items:
                          user?.player_language === 'en'
                            ? ITEM_SORT_SALE_USER
                            : ITEM_SORT_SALE_USER_JA,
                        selectable: true,
                        onClick: handleChangeSort,
                      }}
                      placement="bottomRight"
                      trigger={['click']}
                    >
                      <Button className="flex bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[200px]">
                        <div className="title">
                          {' '}
                          {t(`common.filter.sort.${sort?.valueLanguage}`)}
                        </div>
                      </Button>
                    </Dropdown>

                    <Button
                      className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[44px]"
                      onClick={() => {
                        handleChangeTypeSort()
                      }}
                    >
                      <ArrowUpOutlined
                        style={{ fontSize: 18 }}
                        rotate={typeSort === '1' ? 0 : 180}
                      />
                    </Button>
                  </div>
                </>
              )}
            </div>

            <Spin spinning={isLoading}>
              <div className="flex flex-wrap justify-center gap-4">
                {allItemUser?.map((data, i) => {
                  let type_id
                  switch (data?.type) {
                    case 'Traning_Item':
                      type_id = 0
                      break
                    case 'Enhance_Item':
                      type_id = 1
                      break
                    case 'Fusion_Item':
                      type_id = 2
                      break
                    case 'Regeneration_Item':
                      type_id = 3
                      break
                    default:
                      type_id = 0
                      break
                  }
                  // eslint-disable-next-line
                  data.type_id = type_id
                  // eslint-disable-next-line
                  // data.name = getNameItemV3(data?.item_type)?.name

                  return (
                    <ItemsCardMyAsset
                      t={t}
                      key={i?.toString()}
                      data={data}
                      tema={allTEMAs}
                      button={{
                        title: t('cancel'),
                        functionButton: () => {
                          setNftActive(data)
                          showModalCancelSell()
                        },
                      }}
                      image={
                        data?.type === 'Regeneration_Hash'
                          ? getNameItem(3, data?.nft_id)?.image
                            ? data?.name === 'Regeneration Hash [Random]'
                              ? `${process.env.REACT_APP_BASE_URL_IMAGE}/public/image/image/hash/Regeneration_Hash_Random.png`
                              : `${
                                  process.env.REACT_APP_BASE_URL_IMAGE
                                }/public/image/image/items/${
                                  getNameItem(3, data?.nft_id)?.image
                                }.png`
                            : `${
                                process.env.REACT_APP_BASE_URL_IMAGE
                              }/public/image/image/hash/${getItemGraphic(
                                data?.name,
                              )}`
                          : `${
                              process.env.REACT_APP_BASE_URL_IMAGE
                            }/public/image/image/items/${
                              getNameItemV1(data?.type_id, data?.nft_id)?.image
                            }.png`
                      }
                    />
                  )
                })}
              </div>
            </Spin>

            {!isLoading && totalItemUser === 0 && <NodataMonster />}

            {totalItemUser > 0 && (
              <div className="pagination__common">
                <Pagination
                  onChange={onChangePage}
                  onShowSizeChange={onChangePageSize}
                  className="mt-5"
                  size="small"
                  locale={{ jump_to: 'Go to', page: '' }}
                  total={totalItemUser}
                  // defaultCurrent={page}
                  pageSize={pageSize}
                  current={page}
                  showSizeChanger
                  showQuickJumper
                  showTitle
                />
              </div>
            )}
          </div>
        </Col>
      </Row>

      <CancelSell
        action={{
          isModalOpen: isModalOpen,
          handleOk: handleCancelSell,
          handleCancel: handleCancel,
        }}
        t={t}
        // name={`${nftActive?.name}`}
        name={
          nftActive?.item_type === 'REGENERATION_HASH_OOO_R'
            ? getNameItemV3(nftActive?.item_type)?.name.replace(
                '〇〇〇',
                formatItemName(nftActive.name),
              )
            : nftActive?.item_type === 'Enhance_Item_Crystal'
            ? t('crystalItem', {
                item: nftActive?.name,
              })
            : nftActive?.item_type === 'Enhance_Item_Coach'
            ? nftActive?.name
            : getNameItemV3(nftActive?.item_type)?.name
        }
        loadingModal={isLoadingModal}
        // name={'Monster'}
        // tema={tema}
      />
      <TransactionCompletedCancelSell
        action={{
          isModalOpen: isModalSuccessOpen,
          handleOk: handleShare,
          handleCancel: handleCancelSuccess,
        }}
        t={t}
        // name={`${nftActive?.name}`}
        name={
          nftActive?.item_type === 'REGENERATION_HASH_OOO_R'
            ? getNameItemV3(nftActive?.item_type)?.name.replace(
                '〇〇〇',
                formatItemName(nftActive.name),
              )
            : nftActive?.item_type === 'Enhance_Item_Crystal'
            ? t('crystalItem', {
                item: nftActive?.name,
              })
            : nftActive?.item_type === 'Enhance_Item_Coach'
            ? nftActive?.name
            : getNameItemV3(nftActive?.item_type)?.name
        }
        store={'Marketplace'}
        txHash={txHash}
      />
    </BodyProfile>
  )
}
export default ItemSale
