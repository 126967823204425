import React, { useEffect, useRef, useState } from 'react'
import { Button, Input, Modal, Form, InputNumber, Select, Row, Col } from 'antd'
import { useAccountProfile } from '~/views/redux/hooks/useAccountProfile'
import { USER_INFO } from '~/common/constants'
export default function ModalTransferNFT(props) {
  // eslint-disable-next-line
  const { name, action, t, tokenID, loadingModal, item, quanlityTotal } = props
  const userStorage = JSON.parse(localStorage.getItem(USER_INFO) || null)
  const [form] = Form.useForm()
  const okButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    backgroundColor: '#FAA52C',
    width: '50%',
    height: '48px',
    borderRadius: '4px',
  }
  const cancelButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    width: '50%',
    height: '48px',
  }
  const {
    actions,
    data: { allAddressUser },
  } = useAccountProfile()

  useEffect(() => {
    const params = {
      // search: '0x20D3138e4930a12DC36930122545e7d0517A7F94',
      wallet_address: userStorage?.address_wallet,
    }
    actions.getAllAddressUser(params)
  }, [])
  const handleSubmit = async () => {
    try {
      const values = await form.validateFields()
      const { receiver, quantity } = values
      action?.handleOk(receiver, quantity)
    } catch (error) {
      console.error('Validation Failed:', error)
    }
  }

  const handleCancel = () => {
    action?.handleCancel()
  }

  useEffect(() => {
    if (action?.isModalOpen) {
      form.resetFields()
    }
  }, [action?.isModalOpen])
  const generateItemList = (listAddWhiteList) =>
    listAddWhiteList?.map((item) => ({
      key: item?._id,
      label: (
        <Row>
          <Col span={24}>Name: {item?.user_name}</Col>
          <Col span={24}>Wallet address: {item?.address_wallet}</Col>
        </Row>
      ),
      value: item?.address_wallet,
      name: item?.user_name,
    }))
  return (
    <>
      <Form form={form} preserve={false}>
        <Modal
          key={tokenID}
          width={655}
          centered
          title={
            <h3 className="flex justify-center text-xl font-bold">
              {t('modal.transferNFT.title')}
            </h3>
          }
          open={action?.isModalOpen}
          onCancel={handleCancel}
          footer={[
            <div key="buttons" className="flex justify-center">
              <Button
                key="cancel"
                onClick={handleCancel}
                style={cancelButtonStyle}
              >
                {t('modal.transferNFT.cancel')}
              </Button>
              <Button
                key="ok"
                type="primary"
                style={okButtonStyle}
                loading={loadingModal}
                onClick={handleSubmit}
              >
                {t('modal.transferNFT.ok')}
              </Button>
            </div>,
          ]}
        >
          <div className="pb-5 text-[16px]">
            {t('modal.transferNFT.description', {
              name: `${name}`,
            })}
          </div>
          <div className="text-[16px]">{t('modal.transferNFT.receiver')}</div>
          <div className="py-2">
            <Form.Item
              name="receiver"
              rules={[
                {
                  required: true,
                  message: t('common.schema.required'),
                },
                () => ({
                  validator(rule, value) {
                    const addressPattern = /^0x[a-fA-F0-9]{40}$/
                    if (value && !addressPattern.test(value)) {
                      return Promise.reject(t('common.schema.invalidAddress'))
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              {/* <Input
                maxLength={42}
                autoComplete="off"
                className="input-numberSell"
                placeholder={t('modal.transferNFT.placeholder_receiver')}
              /> */}
              <Select
                placeholder={t('modal.transferNFT.placeholder_receiver')}
                className="addUserList"
                style={{ height: '7vh', color: 'red' }}
                options={generateItemList(allAddressUser)}
                showSearch
                filterOption={(input, option) => {
                  const nameMatch = option?.name
                    ?.toLowerCase()
                    .includes(input?.toLowerCase())
                  const valueMatch = option?.value
                    ?.toLowerCase()
                    .includes(input?.toLowerCase())
                  return nameMatch || valueMatch
                }}
                fieldNames={{ label: 'label', value: 'value' }}
              />
            </Form.Item>
          </div>
          {quanlityTotal && quanlityTotal > 0 && (
            <>
              {' '}
              <div className="flex justify-between">
                <div className="text-[16px]">
                  {t('modal.transferNFT.quantity')}
                </div>
                <div>
                  {t('modal.transferNFT.max', {
                    quanlityTotal: quanlityTotal,
                  })}
                </div>
              </div>
              <div className="py-2">
                <Form.Item
                  name="quantity"
                  rules={[
                    {
                      required: true,
                      message: t('common.schema.required'),
                    },
                    () => ({
                      validator(rule, value) {
                        if (value && value > quanlityTotal) {
                          return Promise.reject(
                            t('common.schema.totalQuantity'),
                          )
                        }
                        if (value === 0) {
                          return Promise.reject(
                            t('common.schema.quantityNumeric'),
                          )
                        }
                        if (value && value <= 0) {
                          return Promise.reject(
                            t('common.schema.quantityNumeric'),
                          )
                        }
                        const integerPattern = /^-?\d+$/
                        if (value && !integerPattern.test(value)) {
                          return Promise.reject(
                            t('common.schema.mustBeNumeric'),
                          )
                        }
                        const numberPattern = /^-?\d+(\.\d+)?$/
                        if (value && !numberPattern.test(value)) {
                          return Promise.reject(
                            t('common.schema.mustBeNumeric'),
                          )
                        } else if (value > item?.quantity) {
                          return Promise.reject(t('common.schema.insufficient'))
                        }
                        return Promise.resolve()
                      },
                    }),
                  ]}
                >
                  <Input
                    maxLength={7}
                    autoComplete="off"
                    className=" input-numberSell"
                    placeholder={t('modal.transferNFT.placeholder_quantity')}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                  />
                </Form.Item>
              </div>
            </>
          )}
        </Modal>
      </Form>
    </>
  )
}
