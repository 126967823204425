import React from 'react'
import { Modal, Row, Col } from 'antd'
import { useShop } from '~/views/redux/hooks/useShop'
import { converNameSeedShop } from '~/common/constants'

export default function RemainingBoxModal(props) {
  const { action, box, t } = props
  const {
    data: { shopItem },
  } = useShop()
  const convertSeed = (seed) => {
    const seedMapping = {
      Ame_chan: 'Ame-chan(KawaiiGirl)',
      AstarCats: 'AstarCats',
      CCT: 'CCT(candyconytown)',
      DEKAUSA: 'DEKAUSA(ALKEMON)',
      Faireaf: 'Faireaf',
      Golem: 'Golem',
      Icelime: 'Icelime(EIEN)',
      Hiyori: 'Hiyori(Wafuku)',
      Kids_Hero: 'Kids Hero(Kids Fight)',
      KidsHero: 'Kids Hero(Kids Fight)',
      KidsVilain: 'Kids Vilain(Kids Fight)',
      Kids_Villain: 'Kids Vilain(Kids Fight)',
      Layla: 'Layla(Love Addicted Girls)',
      ODENPETS: 'ODENPETS(ODENDAO)',
      Ossan: 'Ossan Paradise',
      Ossan_Paradise: 'Ossan Paradise',
      P1: 'P1(X-soldiers)',
      P_1: 'P1(X-soldiers)',
      PlanetMan: 'Sake Barrel PlanetMan(PlanetmMan)',
      Sake_Barrel_PlanetMan: 'Sake Barrel PlanetMan(PlanetmMan)',
      SignWitch: 'SignWitch(Astar Sign Witch)',
      Tofu: 'Tofu',
      Unicorn: 'Unicorn',
      UPA: 'UPA(Debreed)',
      Valius: 'Valius',
      Yamato: 'Yamato(1912)',
    }
    return seedMapping[seed] || seed
  }

  const convertName = (name) => {
    const nameMapping = {
      'Genesis Box': 'GENESIS_BOX',
      'General Box': 'GENERAL_BOX',
    }
    return nameMapping[name] || ''
  }

  const convertType = (type) => {
    const typeMapping = {
      'Group A': 'group_a',
      'Group B': 'group_b',
      'Group C': 'group_c',
      'Group D': 'group_d',
      'Group E': 'group_e',
    }
    return typeMapping[type] || ''
  }

  const convertNameData = (nameData) => {
    const nameDataMapping = {
      'Genesis Box': 'dataGenesis',
      'General Box': 'dataGeneral',
    }
    return nameDataMapping[nameData] || ''
  }

  const getBoxData = () => {
    const boxData = shopItem.find(
      (item) => item.name === convertName(box?.name),
    )
    return boxData ? boxData.detail[convertType(box?.type)] : null
  }

  const roundRates = (data) => {
    const entries = Object.entries(data)
    const total = entries.reduce((sum, [, value]) => sum + value.rate, 0)
    const roundedData = entries.map(([key, value]) => ({
      key,
      ...value,
      rate: (value.rate / total) * 100,
    }))
    const roundedTotal = roundedData.reduce(
      (sum, { rate }) => sum + Math.round(rate * 100) / 100,
      0,
    )
    const difference = 100 - roundedTotal
    if (roundedData.length > 0) {
      roundedData[roundedData.length - 1].rate += difference
    }
    return roundedData
  }

  const boxData = getBoxData()
  const roundedBoxData = boxData
    ? roundRates(boxData[convertNameData(box?.name)] || {})
    : []

  return (
    <Modal
      key={box?.type}
      centered
      title={
        <h3 className="flex justify-center text-xl">
          {box?.name} {box?.type}
        </h3>
      }
      visible={action?.isModalOpen}
      onCancel={action?.handleCancel}
      footer={null}
    >
      <div>
        <Row>
          <Col span={6}></Col>
          <Col span={7}>
            <p>{t('shop.seed')}</p>
          </Col>
          <Col span={5}>
            <p>{t('shop.remaining')}</p>
          </Col>
          <Col
            span={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p>{t('shop.probability')}</p>
          </Col>
        </Row>
        {roundedBoxData.map(({ key, rate, remaining, totalSupply }) => (
          <Row key={key}>
            <Col span={6}>
              <img
                className="block group-hover:scale-110 transition ease-in-out w-[65%] h-full object-contain "
                src={`${process.env.PUBLIC_URL}/imgs/seed/${convertSeed(
                  key,
                )}.png`}
                alt=""
              />
            </Col>
            <Col span={7} style={{ display: 'flex', alignItems: 'center' }}>
              <div className="text-[16px]">{converNameSeedShop(key)}</div>
            </Col>
            <Col span={5} style={{ display: 'flex', alignItems: 'center' }}>
              <p className="text-[16px]">
                {remaining}/{totalSupply}
              </p>
            </Col>
            <Col
              span={6}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
              }}
            >
              <p className="text-[16px]">{rate.toFixed(2)}%</p>
            </Col>
          </Row>
        ))}
      </div>
      <div style={{ fontFamily: 'Rexliafree', marginTop: '20px' }}>
        <p>{t('shop.descriptionBox')}</p>
      </div>
    </Modal>
  )
}
