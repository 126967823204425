import React from 'react'
import { Button, Modal } from 'antd'
import configs from '~/configs'
import { SharedOnTwitter } from '../shared-components/shared-twitter'
export default function ModalTransferNFTSuccess(props) {
  const { action, t, name, txHash } = props
  let link = `${configs.NETWORK.scan}tx/${txHash}`
  const okButtonStyle = {
    fontWeight: 'bold',
    padding: '10px',
    color: '#fff',
    backgroundColor: '#1890FF',
    width: '160px',
    height: '44px',
    borderRadius: '4px',
  }
  const hashStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '50%',
    color: '#40A9FF',
  }

  return (
    <>
      <Modal
        centered
        title={
          <h3 className="flex justify-center text-xl font-bold">
            {t('modal.transfer_completed.title')}
          </h3>
        }
        open={action?.isModalOpen}
        onCancel={action?.handleCancel}
        footer={[
          //   <div key="buttons" className="flex justify-center">
          //     <SharedOnTwitter
          //       title={`${name}`}
          //       okButtonStyle={okButtonStyle}
          //       urlShareTwitter={`${txHash}\n${link}`}
          //     />
          //   </div>,
          null,
        ]}
      >
        <div className="flex justify-center">
          <img
            className="w-[90px] h-[90px] object-contain"
            src={`${process.env.PUBLIC_URL}/imgs/common/success.svg`}
            alt=""
          />
        </div>
        <div className="font__M__plus flex justify-center">
          {t('modal.transfer_completed.description', {
            item: name,
          })}
        </div>
        <div className="my-5 flex justify-between font__M__plus">
          <div>{t('modal.transfer_completed.transaction_hash')}</div>
          <a
            style={hashStyle}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {txHash}
          </a>
        </div>
      </Modal>
    </>
  )
}
