// export const REGEX = {
//   PASSWORD: /^[a-zA-Z0-9]{6,14}$/,
//   NOT_WHITESPACE: /^(?!.*[\s])/,
//   SPECIAL_CHARACTERS: /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/,
//   SPECIAL_CHARACTERS_EMAIL: /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/,
//   NOT_WHITESPACE_START: /^[^\s].*/,
//   INT_NUMBER: /^[0-9]+$/,
//   PHONE_NUMBER: /^[0-9 -]+$/,
//   PROFILE_NAME:
//     /^[a-zA-Z0-9_\p{Script=Hiragana}\p{Script=Katakana}\p{Script=Han}][a-zA-Z0-9_\p{Script=Hiragana}\p{Script=Katakana}\p{Script=Han}\s_-]{0,14}$/u,
//   PROFILE_TWITTER: /^[a-zA-Z0-9_]{1,15}$/,
//   PROFILE_EMAIL: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
// }
export const REGEX = {
  PASSWORD: /^[a-zA-Z0-9]{6,14}$/,
  ADDRESS: /^[a-zA-Z0-9]+$/,
  NOT_WHITESPACE: /^(?!.*[\s])/,
  SPECIAL_CHARACTERS: /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/,
  NOT_WHITESPACE_START: /^\s*[^\s]/,
  INT_NUMBER: /^[0-9]+$/,
  PHONE_NUMBER: /^[0-9 -]+$/,
  PROFILE_NAME:
    /^(?![_\s])[a-zA-Z0-9\p{Script=Hiragana}\p{Script=Katakana}\p{Script=Han}０-９\s_-]{1,15}$/u,
  PROFILE_EMAIL: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
}
