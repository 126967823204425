import { useIntl } from 'react-intl'

export function useTranslation() {
  const intl = useIntl()

  const translate = (key, params = {}) => {
    if (!key) {
      console.warn('Missing translation key') // Cảnh báo nếu key không hợp lệ
      return ''
    }

    return intl.formatMessage(
      { id: key, defaultMessage: key }, // Nếu không tìm thấy id, dùng chính key làm defaultMessage
      { ...params },
    )
  }

  return { t: translate }
}
