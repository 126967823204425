import React, { useEffect, useState } from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
import Header from '~/components/layout-components/Header'
import { useLocation } from 'react-router-dom'
import { scrollToTop } from '~/helpers/common'
import MaintenanceComponent from '~/views/app/maintenance'
import axios from 'axios'

export const AppLayout = ({ children }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [checkDataMaintenance, setCheckDataMaintenance] = useState(null)

  useEffect(() => {
    scrollToTop()
  }, [pathname])
  useEffect(() => {
    const dataMaintenance = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}maintenances/maintenance-status`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        setCheckDataMaintenance(response?.data?.data)
      } catch (error) {
        console.error('Error fetching maintenance data:', error)
      }
    }

    dataMaintenance()
  }, [])

  return (
    <React.Fragment key={'AppLayout'}>
      {checkDataMaintenance === null ? (
        <>
          <Header t={t} />
          {children}
        </>
      ) : (
        <MaintenanceComponent />
      )}
    </React.Fragment>
  )
}

export default React.memo(AppLayout)
