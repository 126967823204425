import { Card, Popover } from 'antd'
import { formatItemName } from '~/helpers/common'
import DescriptionItemJA from '~/assets/data/Description_ItemJA.json'
import DescriptionItemVN from '~/assets/data/Description_ItemVN.json'
import { useAccount } from '~/common/hooks/useAccount'
import { getContentInnateTrait } from '~/helpers/get-content-trait'
import { getTypeCrystalCoach } from '~/common/constants'
export const ParameterItem = (props) => {
  const { t, itemDetail } = props
  const descriptionEN = DescriptionItemVN[itemDetail?.item_type]

  const descriptionJA = DescriptionItemJA[itemDetail?.item_type]
  const textEN = 'You can regenerate nameMonster by using this when Mint.'
  const textJA = 'Mint時にこれを使用すると、nameMonster を再生できます。'
  const { user } = useAccount()
  // Reusable render function
  function renderItemDetail(key) {
    if (itemDetail?.[key] && itemDetail?.[key] !== 0) {
      return (
        <div className="flex justify-between">
          <div className="text-[16px]">{t(`itemDetail.${key}`)}</div>
          <div
            className={`text-[16px] ${
              itemDetail[key] > 0 ? 'text-[#52C41A]' : 'text-[#f70e02]'
            }`}
          >
            {itemDetail[key] > 0 ? '+' : ''}
            {itemDetail[key]}
          </div>
        </div>
      )
    }
    return null
  }

  // Check if any of the fields have valid values
  function shouldShowDescription() {
    const fields = [
      'recovery_stress',
      'recovery_fatigue',
      'training_regimen',
      'training_policy',
      'physical_build',
      'physical',
      'recover_lifespan',
      'degree_of_friendship',
      'condition',
      'body',
      'energy',
      'agi',
      'dex',
      'farm_hp',
      'farm_str',
      'farm_int',
      'farm_dex',
      'farm_agi',
      'farm_vit',
      'fatigue_reduction',
      'hp',
      'int',
      'lifespan',
      'vit',
      'str',
      'rest_enhancement',
      'stress_reduction',
    ]

    return !fields.some(
      (field) => itemDetail?.[field] && itemDetail?.[field] !== 0,
    )
  }
  return (
    <Card className="w-full">
      <div className="flex gap-4">
        <div className="flex-1 bg-[#020f38] py-[16px] px-[24px] min-h-[142px] rounded-[8px] font__M__plus font-bold">
          {itemDetail?.monster && (
            <div className="flex justify-between">
              <div className="text-[16px]">Monster ID</div>
              <div className={`text-[16px]`}>
                <a
                  style={{ color: '#40A9FF' }}
                  href={`/memory-monster/${
                    itemDetail?.monster?.monster_id ??
                    itemDetail?.monster?.monster_nft_id
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {itemDetail?.monster?.monster_id ??
                    itemDetail?.monster?.monster_nft_id}
                </a>
              </div>
            </div>
          )}
          {renderItemDetail('recovery_fatigue')}
          {renderItemDetail('recovery_stress')}
          {renderItemDetail('physical')}
          {renderItemDetail('training_policy')}
          {renderItemDetail('degree_of_friendship')}
          {renderItemDetail('training_regimen')}
          {renderItemDetail('physical_build')}
          {renderItemDetail('recover_lifespan')}
          {renderItemDetail('condition')}
          {renderItemDetail('body')}
          {renderItemDetail('energy')}
          {renderItemDetail('agi')}
          {renderItemDetail('dex')}
          {renderItemDetail('farm_hp')}
          {renderItemDetail('farm_str')}
          {renderItemDetail('farm_int')}
          {renderItemDetail('farm_dex')}
          {renderItemDetail('farm_agi')}
          {renderItemDetail('farm_vit')}
          {renderItemDetail('fatigue_reduction')}
          {renderItemDetail('hp')}
          {renderItemDetail('int')}
          {renderItemDetail('lifespan')}
          {renderItemDetail('vit')}
          {renderItemDetail('str')}
          {renderItemDetail('rest_enhancement')}
          {renderItemDetail('stress_reduction')}
          {shouldShowDescription() && itemDetail?.description && (
            <div className="flex justify-between">
              <div className="text-[16px]">
                {itemDetail?.item_type === 'REGENERATION_HASH_OOO_R'
                  ? (user?.player_language === 'ja' ? textJA : textEN)?.replace(
                      'nameMonster',
                      formatItemName(itemDetail.name),
                    )
                  : itemDetail?.item_type === 'Enhance_Item_Coach' ||
                    itemDetail?.item_type === 'Enhance_Item_Crystal'
                  ? itemDetail?.description?.split('\n').map((line, index) => {
                      const isURL = line.startsWith('URL: ')
                      const url = isURL
                        ? line.replace('URL: ', '').trim()
                        : null

                      return isURL ? (
                        <p key={index}>
                          URL:{' '}
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {url}
                          </a>
                        </p>
                      ) : (
                        <>
                          <p key={index}>
                            {line.replace('Monster Name', itemDetail?.name)}
                          </p>
                        </>
                      )
                    })
                  : user?.player_language === 'ja'
                  ? descriptionJA
                      ?.split(/\n/)
                      ?.map((item, index) => (
                        <div key={index}>{item.trim()}</div>
                      ))
                  : descriptionEN
                      ?.split(/\n/)
                      ?.map((item, index) => (
                        <div key={index}>{item.trim()}</div>
                      ))}
              </div>
            </div>
          )}
        </div>

        <div className="flex-1 bg-[#020f38] py-[16px] px-[24px] rounded-[8px]">
          {itemDetail?.item_type !== 'Enhance_Item_Crystal' &&
          itemDetail?.item_type !== 'Enhance_Item_Coach' ? (
            <div className="flex justify-between items-center font__M__plus font-bold">
              <div className="text-[16px]">{t('itemDetail.supply')}</div>
              <div className="text-[16px] text-[#FAAD14]">
                {itemDetail?.totalAmount}/
                {itemDetail?.unlimited === true
                  ? t('unlimited')
                  : itemDetail?.amountLimit}
              </div>
            </div>
          ) : (
            <></>
          )}

          {itemDetail?.type === 'Regeneration_Hash' && itemDetail.main_seed && (
            <>
              <div className="flex justify-between items-center font__M__plus font-bold mt-4">
                <div className="text-[16px]">
                  {t('monsterDetail.info.mainSeed')}
                </div>
                <div className="text-[16px]">{itemDetail?.main_seed}</div>
              </div>
            </>
          )}
          {itemDetail?.type === 'Regeneration_Hash' && itemDetail.main_seed && (
            <>
              <div className="flex justify-between items-center font__M__plus font-bold mt-4">
                <div className="text-[16px]">
                  {t('monsterDetail.info.subSeed')}
                </div>
                <div className="text-[16px]">{itemDetail?.main_seed}</div>
              </div>
            </>
          )}

          {itemDetail?.item_type === 'REGENERATION_HASH_RANDOM_R' && (
            <>
              <div className="flex justify-between items-center font__M__plus font-bold mt-4">
                <div className="text-[16px]">
                  {t('monsterDetail.info.mainSeed')}
                </div>
                <div className="text-[16px]">Random</div>
              </div>
              <div className="flex justify-between items-center font__M__plus font-bold mt-4">
                <div className="text-[16px]">
                  {t('monsterDetail.info.subSeed')}
                </div>
                <div className="text-[16px]">Random</div>
              </div>
            </>
          )}
          {itemDetail?.type === 'Regeneration_Hash' && (
            <>
              <div className="flex justify-between items-center font__M__plus font-bold mt-4">
                <div className="text-[16px]">
                  {t('monsterDetail.info.regenerationCount')}
                </div>
                <div className="text-[16px]">0/1</div>
              </div>
            </>
          )}
          {itemDetail?.item_type === 'Enhance_Item_Crystal' && (
            <>
              <div className="flex justify-between">
                <div className="text-[16px]">HP</div>
                <div
                  className={`text-[16px] ${
                    itemDetail?.crystal_health?.toFixed(2) > 0
                      ? 'text-[#52C41A]'
                      : 'text-[#f70e02]'
                  }`}
                >
                  {itemDetail?.crystal_health?.toFixed(2) > 0 ? '+' : ''}
                  {itemDetail?.crystal_health?.toFixed(2)}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-[16px]">STR</div>
                <div
                  className={`text-[16px] ${
                    itemDetail?.crystal_strength?.toFixed(2) > 0
                      ? 'text-[#52C41A]'
                      : 'text-[#f70e02]'
                  }`}
                >
                  {itemDetail?.crystal_strength?.toFixed(2) > 0 ? '+' : ''}
                  {itemDetail?.crystal_strength?.toFixed(2)}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-[16px]">INT</div>
                <div
                  className={`text-[16px] ${
                    itemDetail?.crystal_intelligent?.toFixed(2) > 0
                      ? 'text-[#52C41A]'
                      : 'text-[#f70e02]'
                  }`}
                >
                  {itemDetail?.crystal_intelligent?.toFixed(2) > 0 ? '+' : ''}
                  {itemDetail?.crystal_intelligent?.toFixed(2)}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-[16px]">DEX</div>
                <div
                  className={`text-[16px] ${
                    itemDetail?.crystal_dexterity?.toFixed(2) > 0
                      ? 'text-[#52C41A]'
                      : 'text-[#f70e02]'
                  }`}
                >
                  {itemDetail?.crystal_dexterity?.toFixed(2) > 0 ? '+' : ''}
                  {itemDetail?.crystal_dexterity?.toFixed(2)}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-[16px]">AGI</div>
                <div
                  className={`text-[16px] ${
                    itemDetail?.crystal_agility?.toFixed(2) > 0
                      ? 'text-[#52C41A]'
                      : 'text-[#f70e02]'
                  }`}
                >
                  {itemDetail?.crystal_agility?.toFixed(2) > 0 ? '+' : ''}
                  {itemDetail?.crystal_agility?.toFixed(2)}
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-[16px]">VIT</div>
                <div
                  className={`text-[16px] ${
                    itemDetail?.crystal_vitality?.toFixed(2) > 0
                      ? 'text-[#52C41A]'
                      : 'text-[#f70e02]'
                  }`}
                >
                  {itemDetail?.crystal_vitality?.toFixed(2) > 0 ? '+' : ''}
                  {itemDetail?.crystal_vitality?.toFixed(2)}
                </div>
              </div>
            </>
          )}
          {itemDetail?.item_type === 'Enhance_Item_Coach' && (
            <>
              <div className="flex justify-between items-center font__M__plus font-bold text-[16px]">
                {t('descriptionCoach', {
                  type: getTypeCrystalCoach(itemDetail?.coach_value_type),
                  parameter: itemDetail?.coach_value
                    ? `+${itemDetail.coach_value}`
                    : itemDetail?.coach_value,
                })}
              </div>
            </>
          )}
        </div>
      </div>

      {itemDetail?.item_type === 'Enhance_Item_Crystal' && (
        <>
          <div className="mt-3">
            <div className="text-[24px]">
              {t('monsterDetail.battle2.traits')}
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
              <div className="grid grid-cols-1 gap-3 innnate">
                <div className="text-[16px] font__M__plus font-bold ">
                  {t('monsterDetail.battle2.innnate')}
                </div>

                <Popover
                  content={t(
                    `traits.${getContentInnateTrait(
                      itemDetail?.crytal_trait_id,
                    )}`,
                  )}
                >
                  <div
                    className={`bg-[#1d800e] border border-[#00BE09] p-[10px] rounded-[5px] h-[70px]  mb-[82px]
                       flex justify-between items-center`}
                  >
                    <div>
                      <div className="text-[16px] font__M__plus font-bold">
                        {t(
                          `${
                            itemDetail?.crytal_trait_id === ''
                              ? 'None'
                              : itemDetail?.crytal_trait_id
                          }`,
                        )}
                      </div>
                    </div>
                  </div>
                </Popover>
                {/* {innate.length === 0 && (
              <div className="bg-[#1d800e] border border-[#00BE09] p-[10px] rounded-[5px] h-[70px] mb-[82px]  flex justify-between items-center">
                {t(`traits.None`)}
              </div>
            )} */}
              </div>
            </div>
          </div>
        </>
      )}
    </Card>
  )
}
